import {
  Box,
  FormControl,
  ImageListItem,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { IssuedRewardListItem } from "./IssuedRewardListItem";
import { useQuery } from "@apollo/client";
import { GET_ISSUED_REWARD_LIST } from "../../GraphQL/Query";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { UserAuthContext } from "../../contexts/UserAuthContext";

const SearchIconWrapper = styled("div")(() => ({
  paddingRight: "9px",
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const ERewards = () => {
  const { userData } = useContext(UserAuthContext);
  const { data, refetch } = useQuery(GET_ISSUED_REWARD_LIST, {
    variables: {
      orderby: "desc",
      email: userData?.email,
    },
  });
  const [items] = useState(["All", "Pending", "Expired", "Redeemed", "Active"]);
  const [selected, setSelected] = useState<any>("All");
  const handleClick = (id: any) => () => {
    setSelected(id);
  };
  const [searchedTerm, setSearchedTerm] = useState("");
  const [option, setOption] = useState<string[]>([]);
  const navigate = useNavigate();
  const handleChange = (event: SelectChangeEvent<typeof option>) => {
    const {
      target: { value },
    } = event;
    setOption(typeof value === "string" ? value.split(",") : value);
  };
  const sortByOption = [
    {
      key: "Sort by name accending(A-Z)",
      val: "A-Z",
    },
    {
      key: "Sort by name decending (Z-A)",
      val: "Z-A",
    },
  ];
  useEffect(() => {
    if (option[0] === "Z-A") {
      refetch({ orderby: "desc" });
    } else {
      refetch({ orderby: "asc" });
    }
  }, [option, refetch]);
  return (
    <>
      <Stack
        sx={
          {
            // marginTop: '57px',
          }
        }
      >
        <Box sx={style.titleParent}>
          <Typography sx={style.rewardText}>e-Rewards</Typography>
        </Box>
        <ScrollMenu noPolyfill>
          {items.map((item: any) => (
            <Card
              itemId={item}
              title={item}
              key={item}
              onClick={handleClick(item)}
              selected={selected}
            />
          ))}
        </ScrollMenu>

        <Box
          sx={{ backgroundColor: "#E1E6E8", height: "1px", marginTop: "18px" }}
        ></Box>
        <Box sx={style.searchParent}>
          <TextField
            required
            fullWidth
            sx={style.searchinput}
            placeholder={"Search"}
            onChange={(e) => setSearchedTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                </>
              ),
            }}
          />

          <FormControl sx={style.formselect}>
            <InputLabel id="demo-multiple-name-label">Sort</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={option}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
            >
              {sortByOption.map((option) => (
                <MenuItem key={option.key} value={option.val}>
                  {option.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{ backgroundColor: "#E1E6E8", height: "1px", marginTop: "16px" }}
        ></Box>
        {data?.issueRewards
          .filter((reward: any) => {
            return selected !== "All"
              ? reward.ErewardId.includes(searchedTerm) &&
                  reward?.Status.includes(selected)
              : reward.ErewardId.includes(searchedTerm);
          })
          .map((studentReward: any) => {
            return (
              <>
                <IssuedRewardListItem studentReward={studentReward} />
              </>
            );
          })}
        <ImageListItem
          sx={style.itemList}
          onClick={() => navigate("/newEreward")}
        >
          <img
            src={require("../../assets/FAB-erewards.png")}
            style={style.flotingButtonIcon}
            alt={"Logo"}
            loading="lazy"
          />
        </ImageListItem>
      </Stack>
    </>
  );
};
function Card({ onClick, selected, title, itemId }: any) {
  const visibility = useContext(VisibilityContext);

  return (
    <Box
      onClick={() => onClick(visibility)}
      sx={
        selected === itemId
          ? {
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingLeft: "12px",
              paddingRight: "12px",
              borderRadius: "4px",
              height: "32px",
              backgroundColor: "#F2F6FB",
              marginLeft: "6px",
              alignContent: "center",
              display: "flex",
              alignItems: "center",
            }
          : {
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingLeft: "12px",
              paddingRight: "12px",
              borderRadius: "4px",
              height: "32px",
              marginLeft: "6px",
              alignContent: "center",
              display: "flex",
              alignItems: "center",
            }
      }
    >
      <Typography
        sx={
          selected === itemId
            ? {
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.2px",
                color: "#0045B7",
              }
            : {
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.2px",
                color: "#666064",
              }
        }
      >
        {title}
      </Typography>
    </Box>
  );
}
const style = {
  main: {
    height: "100vh",
    width: "100vw",
    marginTop: "70px",
  },
  titleParent: {
    height: "56px",
    marginTop: "9px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "16px",
    paddingBottom: "16px",
  },
  rewardText: {
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "32px",
  },
  itemList: {
    position: "fixed",
    bottom: 21,
    right: 20,
  },
  flotingButtonIcon: { height: 60, width: 60 },
  searchParent: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    marginTop: "16px",
    gap: "8px",
    marginRight: "16px",
    marginLeft: "16px",
  },
  searchinput: {
    height: "48px",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    justifySelf: "center",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.35px",
  },
  formselect: { m: 1, width: "130px" },
  pointImg: { height: "16px", width: "16px" },
  pointParent: {
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};
