import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React from 'react'
import { string } from 'yup'
import { getImgURL } from '../../utils'

type AppProps = {
    data: {
        issueReward: {
            ErewardId: string,
            id: string,
            receipientEmail: string,
        },
    },
    rewardItem: {
        ErewardDescription: string,
        ExpirationDat: string,
        NoteToStudent: string,
        Status: string,
        Qty: string,
        id: string,
        rewards: {
            Media: {
                url: string
            },
            Points: number,
            RewardName: string,
            RewardDescription: string,
            id: string,
            Status: string,
        },

    },
    redeem?: boolean
}
function ConfirmationRewardItem({ rewardItem, data, redeem, status, setStatus }: any) {
    // const handleChange = (event: SelectChangeEvent) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setStatus([{ status: value }]) 
    // };

    return (
        <Box sx={{ width: '100%', paddingTop: '20px', paddingBottom: '16px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '16px' }}>

            <Box sx={{ position: 'relative' }}>
                <img
                    src={getImgURL(rewardItem.rewards.Media.url)}
                    style={{ height: 44, width: 44, borderRadius: '4px' }}
                    alt={'Logo'}
                    loading="lazy"
                />
                <Typography sx={{ position: 'absolute', top: '-10px', right: '-10px', color: '#282427', backgroundColor: '#E4E5E7', paddingTop: '3px', paddingBottom: '3px', paddingLeft: '9px', paddingRight: '9px', borderRadius: '100%' }}>{rewardItem?.Qty
                }</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
                    <Box>
                        <Typography sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '0.2px',
                            color: '#282427',
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                        }}>{rewardItem?.rewards.RewardName}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center' }}>

                        <img
                            src={require("../../assets/Rewardpointsblack.png")}
                          
                            style={{ width: '14px', height: '14px' }}
                            alt={'coupon-svgrepo-com'}
                            loading="lazy"
                        />
                        <Typography sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', color: '#282427' }}>{rewardItem.rewards.Points} pts</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontWeight: '400', fontSize: '14px', lineHeight: '20px', color: '#656667',
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                        }}
                    >{rewardItem?.rewards.RewardDescription}</Typography>
                </Box>
              
                {/* {redeem && <>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        // multiple
                        // value={status ?? status?.find((o: any) => o?.rewardId === rewardItem?.id)?.status ?? rewardItem?.Status}
                        // value={status?.find((o: any) => o?.rewardId === rewardItem?.id)?.status ?? rewardItem?.Status}
                        value={status[0]?.status}
                        defaultValue={data?.issueReward?.Status?.includes('Pending') ? 'Pending' : "Redeemed"}
                        // value={String(data?.issueReward?.Status)}
                        // defaultValue={String(data?.issueReward?.Status)}
                        onChange={handleChange}
                    >
                        {['Pending', 'Redeemed'].map((status) => {
                            return (
                                <MenuItem
                                    key={status}
                                    value={status}
                                // selected={rewardItem?.Status === status}
                                >
                                    {status}
                                </MenuItem>
                            )

                        })}
                    </Select>
                </>} */}


            </Box >
          
        </Box >
    )
}

export default ConfirmationRewardItem