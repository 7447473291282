import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
  query ($email: String) {
    user(where: { email: $email }) {
      id
      name
      LastName
      email
    }
  }
`;

export const GET_REWARD_LIST = gql`
  query ($email: String, $limit: Int, $skip: Int, $orderby: OrderDirection) {
    rewardsCount(where: { User: { email: { equals: $email } } })
    rewards(
      take: $limit
      skip: $skip
      orderBy: { RewardName: $orderby }
      where: { User: { email: { equals: $email } } }
    ) {
      id
      RewardName
      RewardDescription
      Media {
        id
        url
      }
      Points
      AvailableQuantity
    }
  }
`;

export const GET_ISSUED_REWARD_LIST = gql`
  query ($orderby: OrderDirection, $email: String) {
    issueRewards(
      orderBy: { ErewardId: $orderby }
      where: { Sponsor: { email: { equals: $email } } }
    ) {
      id
      ErewardId
      totalPoints
      IssueRewardsItemCount
      IssueRewardsItem {
        id
        rewards {
          id
          RewardName
        }
      }
      receipientEmail
      Status
      createdAt
    }
  }
`;

export const GET_REWARD_TO_ASSIGN = gql`
  query ($idArr: [ID!]) {
    rewards(where: { id: { in: $idArr } }) {
      id
      RewardName
      Points
      Media {
        id
        url
      }
    }
  }
`;

export const GET_CONFIRMATION_ISSUED_REWARD_DETAILS = gql`
  query ($id: ID) {
    issueReward(where: { id: $id }) {
      id
      ErewardId
      receipientEmail
      ErewardDescription
      totalPoints
      ExpirationDate
      NoteToStudent
      Status
      IssueRewardsItem {
        id
        Qty
        rewards {
          id
          RewardName
          RewardDescription
          Points
          Media {
            url
          }
        }
      }
    }
  }
`;

export const GET_REWARD_DETAILS = gql`
  query ($id: ID) {
    reward(where: { id: $id }) {
      id
      RewardName
      RewardDescription
      AvailableQuantity
      Points
      Media {
        id
        url
      }
    }
  }
`;
