import { useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { formatDate } from '../../component/utils';
//import { DELETE_ISSUED_REWARD } from '../../GraphQL/Mutation';
import { GET_CONFIRMATION_ISSUED_REWARD_DETAILS } from '../../GraphQL/Query';
import ConfirmationRewardItem from './ConfirmationRewardItem';



function IssueRewardDetails() {
    const handle = useParams();
    const navigate = useNavigate();
    // 

    const { data, loading, error } = useQuery(GET_CONFIRMATION_ISSUED_REWARD_DETAILS, {
        variables: {
            id: handle.id
        }
    });
    //const [deleteIssueReward,{data:deleteIssueRewardData, loading:deleteIssueRewardLoading, error:deleteIssueRewardError}]= useMutation(DELETE_ISSUED_REWARD)

    // const deleteReward =()=>{
    //     deleteIssueReward({
    //         variables: {
    //             id: handle?.id,
    //         }
    //     })
    // }

    // useEffect(() => {
    //     if (deleteIssueRewardData) {
    //         alert("Delete successfully.")
    //         navigate('/eRewards')
    //     }
    //     if (deleteIssueRewardError) {
    //         alert("Something went wrong while deleting!")
    //     }
    // }, [deleteIssueRewardData, deleteIssueRewardError])

    //
    const [status] = useState<any[]>([]);


    return (
        <Box
            sx={{ paddingBottom: '100px' }}
        >
            <Box sx={{ marginTop: '8px', paddingTop: '16px', paddingBottom: '24px', paddingLeft: '16px', paddingRight: '16px', gap: "8px", display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: '500', fontSize: '22px', lineHeight: '32px', color: '#282427' }}>{data?.issueReward?.ErewardId}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#656667' }}>{formatDate(data?.issueReward?.createdAt)}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', gap: '2px' }}>
                        <img
                            src={require('../../assets/Rewardpoints.png')}
                            style={{ width: '16.67px', height: '14.17px' }}
                            alt={'Logo'}
                            loading="lazy"
                        />
                        <Typography sx={{ fontWeight: '600px', fontSize: '18px', lineHeight: '24px', letterSpacing: '0.1', color: '#282427' }}>{data?.issueReward?.totalPoints} pts</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                    {data?.issueReward?.Status?.map((status: string) => {
                        if (status === 'Pending') {
                            return (<Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', backgroundColor: '#F9D9A4', width: 'auto', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center', borderRadius: '100px' }}>
                                <Box
                                    border={2}
                                    sx={{
                                        height: '9px', width: '9px',
                                        borderRadius: "100%",
                                        borderColor: '#7C5C00'
                                    }}></Box>
                                <Typography
                                    sx={{
                                        display: 'flex', fontWeight: '400', fontSize: '13px', lineHeight: '18px', letterSpacing: '0.2px',
                                        color: '#282427'
                                    }}
                                >Pending</Typography>
                            </Box>)
                        }
                        if (status === 'Active') {
                            return (<Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', backgroundColor: '#F9D9A4', width: 'auto', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center', borderRadius: '100px' }}>
                                <Box
                                    border={2}
                                    sx={{
                                        height: '9px', width: '9px',
                                        borderRadius: "100%",
                                        borderColor: '#7C5C00'
                                    }}></Box>
                                <Typography
                                    sx={{
                                        display: 'flex', fontWeight: '400', fontSize: '13px', lineHeight: '18px', letterSpacing: '0.2px',
                                        color: '#282427'
                                    }}
                                >Active</Typography>
                            </Box>)
                        }
                        if (status === 'Expired') {
                            return (<Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', backgroundColor: '#FC9797', width: 'auto', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center', borderRadius: '100px' }}>
                                <Box sx={{ height: '9px', width: '9px', backgroundColor: '#7F2223', borderRadius: "100%", borderWidth: '2px', borderColor: '#7C5C00' }}></Box>
                                <Typography
                                    sx={{ display: 'flex', fontWeight: '400', fontSize: '13px', lineHeight: '18px', letterSpacing: '0.2px', color: '#282427' }}
                                >Expired</Typography>
                            </Box>)
                        }
                        if (status === 'Redeemed') {
                            return (<Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', backgroundColor: '#BAE7D2', width: 'auto', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center', borderRadius: '100px' }}>
                                <Box
                                    border={2}
                                    sx={{
                                        height: '9px', width: '9px',
                                        borderRadius: "100%",
                                        borderWidth: '2px',
                                        backgroundColor: '#036F50',
                                        borderColor: '#036F50'
                                    }}></Box>
                                <Typography
                                    sx={{
                                        display: 'flex', fontWeight: '400', fontSize: '13px', lineHeight: '18px', letterSpacing: '0.2px',
                                        color: '#282427'
                                    }}
                                >Redeemed</Typography>
                            </Box>)
                        }

                    })}
                    {/* <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#656667' }}>Actssive</Typography>
                    <Typography sx={{ fontWeight: '600', fontSize: '18px', lineHeight: '24px', color: '#282427' }}>Pending</Typography> */}
                </Box>
                <Box sx={{ height: '1px', backgroundColor: '#E1E6E8', marginTop: '24px' }}></Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', paddingTop: '16px' }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '18px', lineHeight: '24px', letterSpacing: '-0.15', color: '#70666C' }}>Rewards</Typography>
                    {data?.issueReward?.IssueRewardsItem?.map((rewardItem: any) => {
                        return (
                            <>
                                <ConfirmationRewardItem data={data} rewardItem={rewardItem} status={status} />
                                <Box sx={{ backgroundColor: '#E1E6E8', width: '100%', height: "1px", marginTop: '10px', marginBottom: '1px' }}></Box>
                            </>
                        )

                    })}

                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', paddingTop: '16px' }}>
                    <Typography sx={{ fontWeight: '400', fontSize: '15px', lineHeight: '24px', letterSpacing: '0.5', color: '#70666C' }}>Information</Typography>


                    <Box sx={{ backgroundColor: '#E1E6E8', width: '100%', height: "1px", marginTop: '10px', marginBottom: '1px' }}></Box>

                    <Box sx={{ paddingTop: '16px', paddingBottom: '16px', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: '15px', lineHeight: '24px', letterSpacing: '0.5px', color: '#70666C' }}>Recipient</Typography>
                        <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>{data?.issueReward?.receipientEmail}</Typography>
                    </Box>

                    <Box sx={{ backgroundColor: '#E1E6E8', width: '100%', height: "1px", marginTop: '10px', marginBottom: '1px' }}></Box>

                    <Box sx={{ paddingTop: '16px', paddingBottom: '16px', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: '15px', lineHeight: '24px', letterSpacing: '0.5px', color: '#70666C' }}>Coupon ID</Typography>
                        <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>{data?.issueReward.id}</Typography>
                    </Box>

                    <Box sx={{ backgroundColor: '#E1E6E8', width: '100%', height: "1px", marginTop: '10px', marginBottom: '1px' }}></Box>


                    <Box sx={{ paddingTop: '16px', paddingBottom: '16px', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: '15px', lineHeight: '24px', letterSpacing: '0.5px', color: '#70666C' }}>Description</Typography>

                        <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>{data?.issueReward.ErewardDescription}</Typography>
                    </Box>



                    <Box sx={{ backgroundColor: '#E1E6E8', width: '100%', height: "1px", marginTop: '10px', marginBottom: '1px' }}></Box>

                    <Box sx={{ paddingTop: '16px', paddingBottom: '16px', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: '15px', lineHeight: '24px', letterSpacing: '0.5px', color: '#70666C' }}>Validity</Typography>

                        <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>{formatDate(data?.issueReward?.createdAt)} - {formatDate(data?.issueReward?.ExpirationDate)}</Typography>
                    </Box>

                    <Box sx={{ backgroundColor: '#E1E6E8', width: '100%', height: "1px", marginTop: '10px', marginBottom: '1px' }}></Box>


                    <Box sx={{ paddingTop: '16px', paddingBottom: '16px', gap: '8px', display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: '15px', lineHeight: '24px', letterSpacing: '0.5px', color: '#70666C' }}>Note to student</Typography>

                        <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>
                            {data?.issueReward?.NoteToStudent}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* <LoadingButton
                        loading={deleteIssueRewardLoading}
                        loadingPosition='center'
                        onClick={() => deleteReward()}
                        variant="outlined"
                        color="error"
                        sx={{ borderRadius: '4px', height: '44px',width:"93%", margin:"auto", alignItems: 'center', display: 'flex',justifyContent:"center"}}
                    >
                        Delete reward
                </LoadingButton> */}

        </Box >
    )
}

export default IssueRewardDetails


const style = {
    buttomButtonParent: { borderTop: 1, borderColor: '#E1E6E8', position: 'fixed', bottom: 0, left: 0, right: 0, height: '77px', backgroundColor: 'white', zIndex: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: "16px", paddingRight: '16px' },
    cencelButton: { height: '44px', width: "fit-content", paddingY: '10px', paddingX: '24px' },
    cencelText: { fontWeight: '500', fontSize: '16px', lineHeight: '24px', letterSpacing: '-0.2px', color: '#282427' },
    publishParent: { borderRadius: '4px', height: '44px', width: "fit-content", backgroundColor: '#AD0067', paddingY: '10px', paddingX: '24px', alignItems: 'center', display: 'flex', gap: '6px' },
    publishParentDisable: { borderRadius: '4px', height: '44px', width: "fit-content", backgroundColor: '#2824271F', paddingY: '10px', paddingX: '24px', alignItems: 'center', display: 'flex', gap: '6px' },
    publishText: { color: '#FFFFFF', fontWeight: '500', fontSize: '16px', lineHeight: '24px', letterSpacing: '-0.2px' }

}