import { LoadingButton } from "@mui/lab";
import { Alert, Box, IconButton, InputAdornment, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Constants } from "../../Constants";
import { useMutation } from "@apollo/client";
import { CREATE_USER_MUTATION } from "../../GraphQL/Mutation";

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string().min(8, "Password should be of minimum 8 characters length.").required('Password is required'),
  FirstName: yup.string().required('First name is required.'),
  LastName: yup.string().required('Last name is required.')
})

export const AuthenticationPage = () => {
  const { t } = useTranslation();
  const { setUserData } = useContext(UserAuthContext);
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const [createUser, { loading, data, error }] = useMutation(CREATE_USER_MUTATION)
  const [open, setOpen] = React.useState(false);
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      FirstName: "",
      LastName: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createUser({
        variables: {
          name: values.FirstName,
          lastName: values.LastName,
          email: values.email.toLowerCase(),
          password: values.password
        }
      })
    }
  });

  useEffect(() => {
    if (data?.createUser?.id) {
      setOpen(true)
    }
    if (error) {

    }
  }, [data, error])
  return (
    <Stack
      direction="column"
      alignItems="center"
      alignContent={'flex-start'}
      justifyContent={'flex-start'}
      justifyItems={'flex-start'}
      height="100vh"
      width="100vw"
    // marginTop={'57px'}
    // spacing={2}
    >
      <Box sx={{ backgroundColor: '#142A50', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: '32px', paddingLeft: '32px', paddingRight: '32px', paddingBottom: '32px' }}>
        <Typography fontWeight={'500'} lineHeight={'32px'} letterSpacing={'-0.18px'} fontSize={'24px'} color={'#FFFFFF'}>{t('create-your-account')}</Typography>
        <Typography fontWeight={'400'} lineHeight={'24px'} fontSize={'16px'} color={'#B3B3BE'}>
          {t('create-account-details')}
        </Typography>
      </Box>

      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ width: '100vw', height: 'auto', marginTop: '32px', paddingLeft: "32px", paddingRight: '32px', gap: '20px', display: 'flex', flexDirection: 'column' }}>
            <TextField required
              value={formik.values.FirstName}
              onChange={formik.handleChange}
              error={formik.touched.FirstName && Boolean(formik.errors.FirstName)}
              helperText={formik.touched.FirstName && formik.errors.FirstName}
              id="FirstName" name="FirstName" label={t('first-name')} variant="outlined" fullWidth />
            <TextField
              value={formik.values.LastName}
              onChange={formik.handleChange}
              error={formik.touched.LastName && Boolean(formik.errors.LastName)}
              helperText={formik.touched.LastName && formik.errors.LastName}
              required id="LastName" name="LastName" label={t('last-name')} variant="outlined" fullWidth />
            <TextField
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required id="email" name="email" label={t('email')} variant="outlined" fullWidth />
            <TextField
              required
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              id="password" name="password"
              label={t('createpassword')}
              type={values.showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {values.showPassword ? <VisibilityOff color={'secondary'} /> : <Visibility color={'secondary'} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

          </Box>
          <Typography sx={{ paddingLeft: '32px', marginTop: '12px', fontWeight: '400px', color: '#666064', fontSize: '13px', lineHeight: '18px', letterSpacing: "0.4px" }}>Password should be at least 8 characters long</Typography>

          <Box sx={{ width: '100vw', height: 'auto', marginTop: '32px', paddingLeft: "32px", paddingRight: '32px', gap: '20px', display: 'flex', flexDirection: 'column' }}>

            <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>
              {t('signup-tnc')}
              <Typography display={'inline'} sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: Constants.color.primary }}>{t('terms-of-use')} </Typography>{t('and')} <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: Constants.color.primary, }} display={'inline'}>{t('privacy-policy')}</Typography>.</Typography>

            <LoadingButton
              type='submit'
              loading={loading}
              loadingPosition='center'
              disabled={loading}
              sx={{ height: "48px", borderRadius: '4px' }} variant="contained" disableElevation>
              <Typography sx={{
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '-0.2px',
                textTransform: 'none',
                borderRadius: '4px'
              }}>{t('sign-up')}</Typography>
            </LoadingButton>
          </Box>
        </form>
      </Box>
      <Box sx={{ width: '100vw', height: 'auto', marginTop: '40px', paddingLeft: "32px", paddingRight: '32px', gap: '20px', display: 'flex', flexDirection: 'column' }}>

        <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>
          {t('already-account')}
          <Typography display={'inline'} sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: Constants.color.primary }}

            onClick={() => navigate('/auth/login')}
          > {t('login')}</Typography> </Typography>

      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} variant='standard' sx={{ width: '100%' }} severity="success">Your account is created!</Alert>
      </Snackbar>
    </Stack>
  );
};
