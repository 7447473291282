import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserAuthContext } from '../../contexts/UserAuthContext'

function Profile() {
    const navigate = useNavigate();
    const { userData } = useContext(UserAuthContext);
    return (
        <Stack sx={{}}>
            <Box
                sx={{ height: '48px', paddingTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}
            ><Typography sx={{ fontWeight: '500', fontSize: '22px', lineHeight: '32px' }}>Profile</Typography></Box>

            <Box>

                <Box
                    borderBottom={1}
                    borderColor={'#E1E6E8'}
                    sx={{ gap: '4px', display: 'flex', flexDirection: 'column', paddingTop: '16px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px' }}
                >
                    <Typography sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', letterSpacing: '0.5px', color: '#70666C' }}>Full name</Typography>
                    <Box sx={{
                        display: 'flex'
                    }}>
                        <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>{userData?.name}</Typography>
                        <Typography sx={{ marginLeft: 'auto', fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#0045B7' }}
                            onClick={() => navigate('/editName')}
                        >Edit</Typography>
                    </Box>
                </Box>
                {/* <Box
                    borderBottom={1}
                    borderColor={'#E1E6E8'}
                    sx={{ gap: '4px', display: 'flex', flexDirection: 'column', paddingTop: '16px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px' }}
                >
                    <Typography sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', letterSpacing: '0.5px', color: '#70666C' }}>Phone number (optional)</Typography>
                    <Box sx={{
                        display: 'flex'
                    }}>
                        <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>No phone number</Typography>
                        <Typography sx={{ marginLeft: 'auto', fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#0045B7' }}>Edit</Typography>
                    </Box>
                </Box> */}
                <Box
                    borderBottom={1}
                    borderColor={'#E1E6E8'}
                    sx={{ gap: '4px', display: 'flex', flexDirection: 'column', paddingTop: '16px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px' }}
                >
                    <Typography sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', letterSpacing: '0.5px', color: '#70666C' }}>Email address</Typography>
                    <Box sx={{
                        display: 'flex'
                    }}>
                        <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>{userData?.email}</Typography>
                        <Typography
                            onClick={() => navigate('/editEmail')}
                            sx={{ marginLeft: 'auto', fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#0045B7' }}>Edit</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{ gap: '4px', display: 'flex', flexDirection: 'column', paddingTop: '16px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px' }}
                >
                    <Typography
                        sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px', letterSpacing: '0.5px', color: '#70666C' }}

                    >Password</Typography>
                    <Box sx={{
                        display: 'flex'
                    }}>
                        <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>********</Typography>
                        <Typography
                            onClick={() => navigate('/editPassword')}
                            sx={{ marginLeft: 'auto', fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#0045B7' }}>Edit</Typography>
                    </Box>
                </Box>

                <Box sx={{
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    paddingTop: '16px'
                }}>
                    <LoadingButton
                        type='submit'
                        loadingPosition='center'
                        sx={{ height: "48px", borderRadius: '4px', width: '100%', alignSelf: 'center', alignContent: 'center', alignItems: 'center' }} variant="contained" disableElevation>
                        <Typography sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '-0.2px',
                            textTransform: 'none',
                            borderRadius: '4px'
                        }}>Done</Typography>
                    </LoadingButton>
                </Box>
            </Box >

        </Stack >
    )
}

export default Profile