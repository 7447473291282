import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_CONFIRMATION_ISSUED_REWARD_DETAILS } from "../../GraphQL/Query";

function QRScan() {
  const navigate = useNavigate();
  const [checkValidId, { data, error }] = useLazyQuery(
    GET_CONFIRMATION_ISSUED_REWARD_DETAILS
  );

  const checkAndOpen = async (text: string) => {
    checkValidId({
      variables: {
        id: text,
      },
    });
  };

  useEffect(() => {
    if (data) navigate(`/RedeemReward/${data.issueReward.id}`);
  }, [data]);

  return (
    <QrReader
      constraints={{
        facingMode: "environment",
      }}
      videoId="QR"
      onResult={(result: any, error: any) => {
        if (!!result) {
          if (result?.text) {
            checkAndOpen(result?.text);
          }
        }
      }}
    />
  );
}

export default QRScan;
