import * as ReactDOMClient from "react-dom/client";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { initReactI18next } from "react-i18next";
import en from "./i18n/en.json";
import zhHK from "./i18n/zh-HK.json";
import { Constants } from "./Constants";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createUploadLink } from "apollo-upload-client";

const resources = {
  en: en,
  "zh-HK": zhHK,
};

i18n.use(initReactI18next).init({
  resources,
  lng: (() => {
    try {
      return JSON.parse(
        localStorage.getItem(Constants.localStorageKeys.language) ?? ""
      );
    } catch {
      return "en";
    }
  })(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

const uploadLink = createUploadLink({
  uri: `${
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BACKEND_API_URL
      : process.env.REACT_APP_BACKEND_API_URL_DEV
  }/api/graphql`,
  credentials: "include",
});
const client = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          rewards: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  }),
});

const container = document.getElementById("root")!;
const root = ReactDOMClient.createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
