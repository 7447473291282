import { gql } from "@apollo/client";

export const CREATE_USER_MUTATION = gql`
mutation(
    $name: String
    $lastName: String
    $email: String
    $password: String
) {
  createUser(
    data:{
      name: $name,
      LastName: $lastName,
      email: $email,
      password: $password
    }
  ){   
    id
  }
}
`;

export const AUTH_MUTATION = gql`
mutation(
    $email: String!
    $password: String!
)
{
    authenticateUserWithPassword(
      email: $email,
      password: $password
    ){
      __typename
    }
  }`;


export const CREATE_REWARD = gql`
mutation(
$rewardname: String!
$rewarddescription: String!
$points: Int
$qty: Int
$image: Upload!
$email: String!
)
{
  createReward(
    data:{
      RewardName: $rewardname,
      RewardDescription: $rewarddescription,
      Points: $points,
      AvailableQuantity: $qty,
      Media:{upload: $image},
      User:{connect:{email: $email}}
    }
  ){
    id
    User{
      id
    }
  }
}`;




export const ISSUE_REWARD_TO_STUDENT = gql`
mutation(
  $rewardItem: IssueRewardItemRelateToManyForCreateInput
  $rewardId: String
  $ErewardDescription: String
  $receipientEmail: String
  $NoteToStudent: String
  $totalPoints: Int
  $Sponsor: String
  $createdAt: DateTime
  $ExpirationDate: DateTime
  $timeZone: String
) {
  createIssueReward(
    data: {
      IssueRewardsItem: $rewardItem
      ErewardId: $rewardId
      ErewardDescription: $ErewardDescription
      receipientEmail: $receipientEmail
      NoteToStudent: $NoteToStudent
      totalPoints: $totalPoints
      createdAt:$createdAt
      ExpirationDate: $ExpirationDate
      Sponsor: { connect: { email: $Sponsor } }
      timeZone: $timeZone
    }
  ) {
    id
  }
}
`;


export const REDEEM_ISSUED_REWARD = gql`
mutation (
  $id: ID
  $status: [String!]
  ) {
  updateIssueReward(
    where: { id: $id }
    data: { Status: $status }
  ) {
    id
  }
}
`;

export const updateName = gql`
mutation
(
  $email: String
  $fname: String
  $lname: String
)
{
  updateUser(
    where:{
      email: $email
    },
    data:{
      name: $fname,
      LastName: $lname
    }
  ){
    id
    name
    email
  }
}`;

export const updatePassword = gql`
mutation(
  $email: String
  $newPassword: String
){
  updateUser(
    where:{
      email: $email
    },
    data:{
    password: $newPassword
    }
  ){
    id
  }
}`
export const updateEmail = gql`
mutation(
  $currentEmail: String
  $newEmail: String
  ){
  updateUser(
    where:{
      email: $currentEmail
    },
     data:{
       email: $newEmail
    }
  ){
    id
  }
}`;

export const UPDATE_QR_IMAGE = gql`
mutation
(
  $image: Upload!
  $id: ID
)
{
  updateIssueReward(
    where:{
      id: $id
    },
    data:{
    QRImage:{upload: $image}
    }
  ){
    id
  }
}`;

export const UPDATE_REWARD_WITHOUT_IMAGE = gql`
mutation (
  $id: ID!,
  $rewardName: String,
  $rewardDescription: String,
  $availableQuantity: Int,
  $points: Int
) {
  updateReward(where: {id: $id}, 
    data: {
    RewardName: $rewardName,
    RewardDescription: $rewardDescription,
    AvailableQuantity: $availableQuantity,
    Points: $points
  }
  ) {
    id
    RewardName
    RewardDescription
    AvailableQuantity
    Points
  }
}`;


export const UPDATE_REWARD_WITH_IMAGE = gql`
mutation(
  $id: ID!
  $rewardName: String
  $rewardDescription: String
  $availableQuantity: Int
  $points: Int
  $imageUrl: Upload!
) {
  updateReward(
    where: { id: $id }
    data: {
      RewardName: $rewardName
      RewardDescription: $rewardDescription
      AvailableQuantity: $availableQuantity
      Points: $points
      Media: { 
      	upload: $imageUrl
    }
    }
  ) {
    id
    RewardName
    RewardDescription
    AvailableQuantity
    Points
    Media {
      url
    }
  }
}
`;

export const DELETE_REWARD = gql`
mutation
 ($id: ID)
{
   deleteReward(where:{id:$id}){
     id
   }
 }`;