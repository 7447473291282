import {
  Alert,
  CircularProgress,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_REWARD,
  UPDATE_REWARD_WITHOUT_IMAGE,
  UPDATE_REWARD_WITH_IMAGE,
} from "../../GraphQL/Mutation";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { GET_REWARD_DETAILS } from "../../GraphQL/Query";
import { getImgURL } from "../../utils";

const validationSchema = yup.object({
  rewardname: yup.string(),
  rewarddescription: yup.string(),
  image: yup.object(),
  rewardvalue: yup.string(),
  quantity: yup.number(),
});

export const EditReward = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(GET_REWARD_DETAILS, {
    variables: {
      id: id,
    },
  });

  const [image, setImage] = useState<any>(
    getImgURL(data?.reward?.Media?.url)
  );

  const { userData } = useContext(UserAuthContext);
  const [open, setOpen] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const navigate = useNavigate();
  const [
    editRewardWithoutImage,
    {
      data: editRewardWithoutImageData,
      error: editRewardWithoutImageError,
      loading: editRewardWithoutImageLoading,
    },
  ] = useMutation(UPDATE_REWARD_WITHOUT_IMAGE);

  const [
    editRewardWithImage,
    {
      data: editRewardWithImageData,
      error: editRewardWithImageError,
      loading: editRewardWithImageLoading,
    },
  ] = useMutation(UPDATE_REWARD_WITH_IMAGE);

  const formik = useFormik({
    initialValues: {
      rewardname: data?.reward?.RewardName,
      rewarddescription: data?.reward?.RewardDescription,
      image: {
        preview: "",
        raw: "",
      },
      rewardvalue: data?.reward?.Points,
      quantity: data?.reward?.AvailableQuantity,
      file: undefined,
    },

    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      if (!values.file) {
        editRewardWithoutImage({
          variables: {
            id: id,
            rewardName: values.rewardname,
            rewardDescription: values.rewarddescription,
            availableQuantity: values.quantity,
            points: values.rewardvalue,
          },
        });
      } else {
        editRewardWithImage({
          variables: {
            id: id,
            rewardName: values.rewardname,
            rewardDescription: values.rewarddescription,
            availableQuantity: values.quantity,
            points: values.rewardvalue,
            imageUrl: values.file,
          },
        });
      }
    },
  });

  useEffect(() => {
    if (data && data?.reward)
      setImage(`http://localhost:3000` + data.reward.Media?.url);
  }, [data]);

  useEffect(() => {
    if (editRewardWithoutImageData || editRewardWithImageData) {
      setOpen(true);
    }
    if (editRewardWithoutImageError || editRewardWithImageError) {
      alert("something went wrong!");
    }
  }, [
    editRewardWithoutImageData,
    editRewardWithoutImageError,
    editRewardWithImageData,
    editRewardWithImageError,
  ]);

  const [
    deleteReward,
    {
      data: deleteRewardData,
      loading: deleteRewardLoading,
      error: deleteRewardError,
    },
  ] = useMutation(DELETE_REWARD);

  const deleteRewardButton = () => {
    deleteReward({
      variables: {
        id: id,
      },
    });
  };

  useEffect(() => {
    if (deleteRewardData) {
      alert("Delete successfully.");
      navigate("/rewardlist");
    }
    if (deleteRewardError) {
      alert("Something went wrong while deleting!");
    }
  }, [deleteRewardData, deleteRewardError]);

  const [isDirty, setIsDirty] = useState(false);

  return (
    <>
      <Stack sx={style.main}>
        <Grid
          container
          color="inherit"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          {loading && <CircularProgress />}
        </Grid>

        {data && (
          <>
            <Box
              sx={style.titleParent}
              borderBottom={8}
              borderColor={"#E1E6E8"}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "95%" }}
              >
                <Typography sx={style.title}>
                  {formik.values.rewardname ?? data?.reward?.RewardName}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                  >
                    {formik.values.quantity ?? data?.reward?.AvailableQuantity}{" "}
                    {formik.values.quantity === 1 ? "item" : "items"}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("../../assets/Rewardpoints.png")}
                      alt={"Logo"}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "3px",
                      }}
                      loading="lazy"
                    />
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "24px",
                        fontWeight: "600",
                      }}
                    >
                      {formik.values.rewardvalue ?? data?.reward?.Points} pts
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={style.formParent}>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={style.formInputParent}>
                  <Box sx={style.inputSec1}>
                    <TextField
                      value={
                        formik.values.rewardname ?? data?.reward?.RewardName
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        setIsDirty(true);
                      }}
                      defaultValue={data?.reward?.RewardName}
                      error={
                        formik.touched.rewardname &&
                        Boolean(formik.errors.rewardname)
                      }
                      required
                      id="rewardname"
                      name="rewardname"
                      label="Item name"
                      variant="outlined"
                      fullWidth
                    />
                    <TextField
                      id="rewarddescription"
                      name="rewarddescription"
                      value={
                        formik.values.rewarddescription ??
                        data?.reward?.RewardDescription
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        setIsDirty(true);
                      }}
                      multiline
                      defaultValue={data?.reward?.RewardDescription}
                      minRows={3}
                      placeholder="Item description"
                      error={
                        formik.touched.rewarddescription &&
                        Boolean(formik.errors.rewarddescription)
                      }
                      required
                      label="Item description"
                      variant="outlined"
                      fullWidth
                    />
                  </Box>

                  <Box sx={style.divider}></Box>

                  <Box sx={style.inputSec2}>
                    <Typography sx={style.rewardDetails}>
                      Item details
                    </Typography>
                    <Box
                      sx={{
                        border: 1,
                        marginTop: "16px",
                        borderColor: "#E1E6E8",
                        width: "100%",
                      }}
                    ></Box>
                    <Typography sx={style.mediaText}>Media</Typography>
                    <Box
                      sx={{ position: "relative", ...style.fileUploadParent }}
                    >
                      {image ? (
                        <>
                          <label htmlFor="image">
                            <img
                              src={image}
                              style={{
                                height: 130,
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                              }}
                              alt="Logo"
                              loading="lazy"
                              color="red"
                            />
                            <Typography
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                cursor: "pointer",
                                ...style.uploadImgText,
                              }}
                            >
                              Change Image
                            </Typography>
                          </label>
                        </>
                      ) : (
                        <>
                          <label htmlFor="image">
                            <Typography sx={style.uploadImgText}>
                              Upload image
                            </Typography>
                          </label>
                        </>
                      )}
                      <input
                        type="file"
                        id="image"
                        name="image"
                        style={style.file}
                        onChange={(event) => {
                          setIsDirty(true);
                          if (event?.target?.files) {
                            setImage(
                              URL.createObjectURL(event?.target.files[0])
                            );
                            formik.setFieldValue(
                              "file",
                              event?.target?.files[0]
                            );
                          }
                        }}
                      />
                    </Box>

                    <Box sx={style.divider1}></Box>

                    <Typography sx={style.valueText}>Value</Typography>
                    <Typography sx={style.valueDesc}>
                      This will be deducted from the student’s total accumulated
                      points
                    </Typography>
                    <TextField
                      sx={style.rewardvalInput}
                      value={formik.values.rewardvalue}
                      defaultValue={data?.reward?.Points}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setIsDirty(true);
                      }}
                      type="number"
                      required
                      id="rewardvalue"
                      name="rewardvalue"
                      label="Reward points"
                      variant="outlined"
                      fullWidth
                    />

                    <Box sx={style.divider1}></Box>

                    <Typography sx={style.qtyText}>
                      Available quantity
                    </Typography>
                    <TextField
                      sx={style.rewardvalInput}
                      value={formik.values.quantity}
                      defaultValue={data?.reward?.AvailableQuantity}
                      type="number"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setIsDirty(true);
                      }}
                      required
                      id="quantity"
                      name="quantity"
                      label="Reward quantity"
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Paper sx={style.buttomButtonParent} elevation={0}>
                  <LoadingButton
                    loading={deleteRewardLoading}
                    loadingPosition="center"
                    onClick={() => deleteRewardButton()}
                    variant="outlined"
                    color="error"
                    sx={{
                      borderRadius: "4px",
                      height: "44px",
                      width: "fit-content",
                      paddingY: "10px",
                      paddingX: "24px",
                      alignItems: "center",
                      display: "flex",
                      gap: "6px",
                    }}
                  >
                    Delete
                  </LoadingButton>

                  <LoadingButton
                    type="submit"
                    loading={
                      editRewardWithoutImageLoading ||
                      editRewardWithImageLoading
                    }
                    loadingPosition="center"
                    disabled={
                      !isDirty ||
                      editRewardWithoutImageLoading ||
                      editRewardWithImageLoading
                    }
                    sx={style.publishParent}
                    variant="contained"
                    disableElevation
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "-0.2px",
                        textTransform: "none",
                      }}
                    >
                      Update
                    </Typography>
                  </LoadingButton>
                </Paper>
              </form>
            </Box>

            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                variant="standard"
                sx={{ width: "100%" }}
                severity="success"
              >
                Reward Updated Successfully!
              </Alert>
            </Snackbar>
          </>
        )}
      </Stack>
    </>
  );
};

const style = {
  main: {
    height: "100vh",
    width: "100vw",
    // marginTop: '70px',
  },
  titleParent: {
    width: "100%",
    marginTop: "14px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "16px",
    paddingBottom: "16px",
  },
  title: {
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "32px",
    width: "90%",
  },
  formParent: { marginTop: "24px", height: "auto", width: "100vw" },
  formInputParent: {
    height: "100vh",
    width: "100vw",
    marginBottom: "100px",
  },
  inputSec1: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  textareaStyle: {
    borderRadius: "4px",
    borderColor: "#CEC7CB",
    borderWidth: "1px",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "16px",
    paddingRight: "16px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.35px",
  },
  divider: {
    border: 1,
    marginTop: "24px",
    borderColor: "#E1E6E8",
    width: "100%",
  },
  inputSec2: { marginTop: "24px", paddingLeft: "16px", paddingRight: "16px" },
  rewardDetails: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "36px",
    letterSpacing: "-0.2px",
    color: "#282427",
  },
  mediaText: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.2px",
    color: "#282427",
    marginTop: "16px",
  },
  fileUploadParent: {
    border: 1,
    marginTop: "16px",
    borderColor: "#CEC7CB",
    width: "100%",
    height: "130px",
    borderStyle: "dotted",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadImgText: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "4px",
    paddingBottom: "4px",
    backgroundColor: "#F2F6FB",
    color: "#0045B7",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
  },
  file: { display: "none" },
  divider1: {
    border: 1,
    marginTop: "16px",
    borderColor: "#E1E6E8",
    width: "100%",
  },
  valueText: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.2px",
    color: "#282427",
    marginTop: "16px",
  },
  valueDesc: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#70666C",
    marginTop: "4px",
  },
  rewardvalInput: { marginTop: "16px" },
  qtyText: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.2px",
    color: "#282427",
    marginTop: "16px",
  },
  buttomButtonParent: {
    borderTop: 1,
    borderColor: "#E1E6E8",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "77px",
    backgroundColor: "white",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  cencelButton: {
    height: "44px",
    width: "fit-content",
    paddingY: "10px",
    paddingX: "24px",
  },
  cencelText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
    color: "#282427",
  },
  publishParent: {
    borderRadius: "4px",
    height: "44px",
    width: "fit-content",
    backgroundColor: "#AD0067",
    paddingY: "10px",
    paddingX: "24px",
    alignItems: "center",
    display: "flex",
    gap: "6px",
  },
  publishText: {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
  },
};
