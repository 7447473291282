import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Paper, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { updateName } from "../../GraphQL/Mutation";

const validationSchema = yup.object({
  FirstName: yup.string().required("First name is required."),
  LastName: yup.string().required("Last name is required."),
});

function EditName() {
  const [updated, setUpdated] = useState(false);
  const [updateUserName, { loading, data }] = useMutation(updateName);
  const { userData } = useContext(UserAuthContext);
  const { setUserData } = useContext(UserAuthContext);

  const formik = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      updateUserName({
        variables: {
          email: userData?.email,
          fname: formik.values.FirstName,
          lname: formik.values.LastName,
        },
      });
    },
  });

  useEffect(() => {
    if (data) {
      setUserData({
        email: data?.updateUser?.email,
        name: data?.updateUser?.name,
        id: data?.updateUser?.id,
      });
      setUpdated(true);
    }
  }, [data, setUserData]);
  return (
    <Stack>
      <Box
        sx={{
          paddingTop: "16px",
          paddingLeft: "16px",
          paddingRight: "16px",
          gap: "4px",
          paddingBottom: "16px",
        }}
      >
        <Typography
          sx={{ fontWeight: "500", fontSize: "22px", lineHeight: "32px" }}
        >
          Change your name
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "24px",
            letterSpacing: "0.2px",
            color: "#70666C",
          }}
        >
          Edit the name associated with your LearntoEarn Rewards account.
        </Typography>
      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "16px",
          display: "flex",
          gap: "20px",
          flexDirection: "column",
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          style={{ gap: "20px", display: "flex", flexDirection: "column" }}
        >
          <TextField
            required
            fullWidth
            sx={{ display: "flex" }}
            label="First Name"
            value={formik.values.FirstName}
            onChange={formik.handleChange}
            error={formik.touched.FirstName && Boolean(formik.errors.FirstName)}
            helperText={formik.touched.FirstName && formik.errors.FirstName}
            id="FirstName"
            name="FirstName"
          />
          <TextField
            required
            fullWidth
            sx={{ display: "flex" }}
            label="Last Name"
            value={formik.values.LastName}
            onChange={formik.handleChange}
            error={formik.touched.LastName && Boolean(formik.errors.LastName)}
            helperText={formik.touched.LastName && formik.errors.LastName}
            id="LastName"
            name="LastName"
          />
          <Paper sx={style.buttomButtonParent} elevation={0}>
            <LoadingButton
              type="submit"
              loading={loading}
              loadingPosition="center"
              disabled={updated ?? loading}
              variant="contained"
              disableElevation
              fullWidth
              sx={
                !formik.values.FirstName || !formik.values.LastName
                  ? {
                      borderRadius: "4px",
                      height: "44px",
                      width: "fit-content",
                      backgroundColor: "#2824271F",
                      paddingY: "10px",
                      paddingX: "24px",
                      alignItems: "center",
                      display: "flex",
                      gap: "6px",
                      marginLeft: "auto",
                    }
                  : {
                      borderRadius: "4px",
                      height: "44px",
                      width: "fit-content",
                      backgroundColor: "#AD0067",
                      paddingY: "10px",
                      paddingX: "24px",
                      alignItems: "center",
                      display: "flex",
                      gap: "6px",
                      marginLeft: "auto",
                    }
              }
            >
              Update
            </LoadingButton>
          </Paper>
        </form>
      </Box>
    </Stack>
  );
}

export default EditName;

const style = {
  buttomButtonParent: {
    borderTop: 1,
    borderColor: "#E1E6E8",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "77px",
    backgroundColor: "white",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  cencelButton: {
    height: "44px",
    width: "fit-content",
    paddingY: "10px",
    paddingX: "24px",
  },
  cencelText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
    color: "#282427",
  },
  publishParent: {
    borderRadius: "4px",
    height: "44px",
    width: "fit-content",
    backgroundColor: "#AD0067",
    paddingY: "10px",
    paddingX: "24px",
    alignItems: "center",
    display: "flex",
    gap: "6px",
  },
  publishParentDisable: {
    borderRadius: "4px",
    height: "44px",
    width: "fit-content",
    backgroundColor: "#2824271F",
    paddingY: "10px",
    paddingX: "24px",
    alignItems: "center",
    display: "flex",
    gap: "6px",
  },
  publishText: {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
  },
};
