import { Box, Typography } from "@mui/material"
import { getImgURL } from "../../utils"
import { useNavigate } from "react-router";
import { rewardType } from "../type"


type AppProps = {
    reward: rewardType,
    index: number,
    
}

export const RewardListItem = ({ reward, index }: AppProps) => {
    
    const navigate = useNavigate();
    return (<>
        <Box
            key={index}
            sx={style.main}
            onClick={() => navigate(`/editreward/${reward?.id}`)}
            >
            
            <Box>
                <img
                    src={getImgURL(reward?.Media?.url)}
                    style={style.image}
                    alt={'coupon-svgrepo-com'}
                    loading="lazy"
                />
            </Box>
            <Box sx={style.detailParent}>
                <Box sx={style.detailtitleParent}>
                    <Box
                        sx={style.rowParent}>
                        <Box sx={style.nameParent}>
                            <Typography
                                sx={style.name}>{reward?.RewardName}</Typography>
                        </Box>
                        <Box sx={style.pointParent}>
                            <img
                                src={require('../../assets/Rewardpoints.png')}
                                style={style.pointImg}
                                alt={'Logo'}
                                loading="lazy"
                            />
                            <Typography sx={style.pointText}>{reward?.Points} pts</Typography>
                        </Box>
                    </Box>
                    <Box sx={style.descriptionParent}>
                        <Typography
                            sx={style.descriptionText}
                        >{reward?.RewardDescription}</Typography>
                    </Box>
                </Box>
                <Box sx={style.qty}>
                    <Typography sx={style.qtyText}>{reward?.AvailableQuantity} item available</Typography>
                </Box>
            </Box>
        </Box>
    </>)
}

const style = {
    main: { display: 'flex', flexDirection: 'row', alignItems: 'flex-start', height: 'auto', width: '100%', paddingLeft: '16px', paddingRight: '16px', paddingTop: '16px', paddingBottom: '16px' },
    image: { height: "44px", width: "44px" },
    detailParent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingRight: '16px'
    },
    detailtitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '16px',
        width: '100%'
    },
    rowParent: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    nameParent: { flex: 1 },
    name: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "1",
        WebkitBoxOrient: "vertical",
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: '#282427',
    },
    pointParent: { alignSelf: 'flex-start', display: 'flex', flexDirection: 'row', alignItems: 'center' },
    pointImg: { height: "16px", width: "16px" },
    pointText: { marginLeft: '2px' },
    descriptionParent: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '4px'
    },
    descriptionText: {
        color: '#656667',
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
    },
    qty: {
        marginLeft: '16px',
        marginTop: '12px',
    },
    qtyText: { color: '#656667', fontWeight: '400', fontSize: '14px', lineHeight: '20px' }
}