import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import {
  Drawer,
  ImageListItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { UserAuthContext } from "../contexts/UserAuthContext";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useLocation, useNavigate, Link } from "react-router-dom";

const settings = ["Profile", "Logout"];

function ResponsiveAppBar({ isUserLoggedIn }: { isUserLoggedIn: boolean }) {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorElUser);
  const { logout } = React.useContext(UserAuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = React.useState(false);
  const { userData } = React.useContext(UserAuthContext);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElUser) {
      setAnchorElUser(event.currentTarget);
    } else {
      setAnchorElUser(null);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleProfileClick = (setting: string) => {
    if (setting === "Profile") {
      setAnchorElUser(null);
      navigate("/profile");
    }
    if (setting === "Logout") {
      setAnchorElUser(null);
      logout();
    }
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState(open);
    };

  const list = (anchor: any) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem key={"s"} disablePadding>
          <ListItemButton
            sx={
              location?.pathname === "/Home"
                ? {
                    backgroundColor: "#F4EFF4",
                    borderLeft: 3,
                    borderColor: "#AD0067",
                  }
                : {}
            }
            onClick={() => navigate("/Home")}
          >
            <ListItemIcon>
              <img
                src={require("../assets/team_dashboard.png")}
                style={{ height: 20, width: 20 }}
                alt={"Logo"}
                loading="lazy"
              />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"s"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/rewardlist")}
            sx={
              location?.pathname === "/rewardlist"
                ? {
                    backgroundColor: "#F4EFF4",
                    borderLeft: 3,
                    borderColor: "#AD0067",
                  }
                : {}
            }
          >
            <ListItemIcon>
              <img
                src={require("../assets/Giftbox 1.png")}
                style={{ height: 20, width: 20, fill: "red", stroke: "red" }}
                alt={"Logo"}
                loading="lazy"
                color="red"
              />
            </ListItemIcon>
            <ListItemText primary={"e-Rewards list"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"s"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/eRewards")}
            sx={
              location?.pathname === "/eRewards"
                ? {
                    backgroundColor: "#F4EFF4",
                    borderLeft: 3,
                    borderColor: "#AD0067",
                  }
                : {}
            }
          >
            <ListItemIcon>
              <img
                src={require("../assets/coupon-svgrepo-com 1.jpg")}
                style={{ height: 20, width: 20, fill: "red", stroke: "red" }}
                alt={"Logo"}
                loading="lazy"
                color="red"
              />
            </ListItemIcon>
            <ListItemText primary={"e-Rewards distribution"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"s"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/qrscan")}
            sx={
              location.pathname === "/qrscan"
                ? {
                    backgroundColor: "#F4EFF4",
                    borderLeft: 3,
                    borderColor: "#AD0067",
                  }
                : {}
            }
          >
            <ListItemIcon>
              <QrCodeScannerIcon />
            </ListItemIcon>
            <ListItemText primary={"e-Rewawrds Redemption"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <>
      <AppBar position="fixed" sx={style.main} elevation={0}>
        <Container maxWidth="lg">
          <Toolbar disableGutters style={style.toolbar}>
            {/* <ImageListItem sx ={isUserLoggedIn ? {display:"none"} : style.imageitem}>
              <img
                src={require("../assets/Logo.png")}
                style={style.image}
                alt={"Logo"}
                loading="lazy"
              />
            </ImageListItem> */}

            {isUserLoggedIn && (
              <Box sx={style.drawerParent}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleDrawer(true)}
                  color="inherit"
                >
                  <MenuIcon color={"action"} />
                </IconButton>
                <Drawer
                  anchor={"left"}
                  open={state}
                  onClose={toggleDrawer(false)}
                >
                  {list("left")}
                </Drawer>
              </Box>
            )}

            <ImageListItem
              sx={isUserLoggedIn ? style.isLoggedInLogo : style.notLoggedIn}
            >
              <Link to="/Home">
                <img
                  src={require("../assets/Logo.png")}
                  style={style.image}
                  alt={"Logo"}
                  loading="lazy"
                />
              </Link>
            </ImageListItem>

            {isUserLoggedIn && (
              <Box sx={style.profileMain}>
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={style.iconbutton}
                  >
                    <Avatar
                      alt={userData?.name}
                      src="/static/images/avatar/2.jpg"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={style.menu}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  onClose={handleCloseUserMenu}
                  onClick={handleCloseUserMenu}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => handleProfileClick(setting)}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Box sx={{ marginBottom: "57px" }}></Box>
    </>
  );
}
export default ResponsiveAppBar;

const style = {
  main: {
    borderBottom: 1,
    borderColor: "#0000001F",
    top: "0px",
    overflow: "hidden",
    width: "100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "#0000001F",
  },
  toolbar: { justifyContent: "space-between" },
  //imageitem: { mr: 2, display: { xs: "none", md: "none" } },
  image: { height: "26.5px", width: "64px" },
  drawerParent: { flexGrow: 1, display: { xs: "flex", md: "flex" , lg: "flex" } },
  isLoggedInLogo: {
    mr: 2,
    display: { xs: "flex", md: "flex", lg:"flex" },
    flexGrow: 1,
    fontFamily: "monospace",
    fontWeight: 700,
    letterSpacing: ".3rem",
    color: "inherit",
    textDecoration: "none",
    alignItems :"center"
  },
  notLoggedIn: {
    mr: 2,
    display: { xs: "flex", md: "flex", lg:"flex" },
    flexGrow: 1,
    fontFamily: "monospace",
    fontWeight: 700,
    letterSpacing: ".3rem",
    color: "inherit",
    textDecoration: "none",
    justifyContent: "center",
  },
  mobileLogo: { height: 32, width: 50.91 },
  profileMain: { flexGrow: 0 },
  iconbutton: { p: 0 },
  menu: { mt: "45px" },
};
