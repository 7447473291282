import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConfirmationRewardItem from "./ConfirmationRewardItem";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_CONFIRMATION_ISSUED_REWARD_DETAILS } from "../../GraphQL/Query";
import { formatDate } from "../../component/utils";

function Confirmation() {
  const location = useLocation();
  const navigate = useNavigate();

  const confirmId = location?.state?.createIssueReward?.id;
  const { data } = useQuery(GET_CONFIRMATION_ISSUED_REWARD_DETAILS, {
    variables: {
      id: confirmId,
    },
  });

  useEffect(() => { }, [data]);

  return (
    <Stack>
      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "28px",
          paddingBottom: "28px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            gap: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "22px",
              lineHeight: "32px",
              textAlign: "center",
              color: "#282427",
            }}
          >
            {data?.issueReward?.ErewardId}
          </Typography>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "15px",
              lineHeight: "24px",
              textAlign: "center",
              color: "#70666C",
            }}
          >
            Your e-Reward has been created and an email has been sent to your
            recipient.
          </Typography>
        </Box>

        <Box
          sx={{
            gap: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "16px",
            paddingBottom: "16px",
            width: "100%",
          }}
        >
          <Button
            onClick={() => navigate("/eRewards")}
            sx={{
              borderRadius: "4px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#AD0067",
              height: "48px",
              color: "#FFFFFF",
              paddingTop: "12px",
              paddingBottom: "12px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "-0.2px",
                textTransform: "none",
              }}
            >
              Back to e-Rewards
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "21px",
          marginBottom: "12px",
          marginLeft: "16px",
          marginRight: "16px",
        }}
      >
        <Box
          sx={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "21px",
            paddingBottom: "21px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
          border={1}
          borderColor={"#CEC7CB"}
          borderRadius={"8px"}
        >
          <CheckCircleIcon
            color={"success"}
            sx={{ height: "60px", width: "60px" }}
          />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "15px",
                lineHeight: "24px",
                letterSpacing: "0.2px",
                color: "black",
              }}
            >
              We’ve emailed this e-Reward to
              <Typography
                display={"inline"}
                sx={{
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "24px",
                  letterSpacing: "0.2px",
                  color: "black",
                }}
              >
                {" "}
                {data?.issueReward?.receipientEmail}
              </Typography>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              paddingTop: "16px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "15px",
                lineHeight: "24px",
                letterSpacing: "0.5",
                color: "#70666C",
              }}
            >
              Reward details
            </Typography>
            {data?.issueReward?.IssueRewardsItem?.map(
              (rewardItem: {
                ErewardDescription: string;
                ExpirationDat: string;
                NoteToStudent: string;
                Status: string;
                Qty: string;
                id: string;
                rewards: {
                  Media: {
                    url: string;
                  };
                  Points: number;
                  RewardName: string;
                  RewardDescription: string;
                  Status: string;
                  id: string;
                };
              }) => {
                return (
                  <ConfirmationRewardItem data={data} rewardItem={rewardItem} />
                );
              }
            )}

            <Box
              sx={{
                backgroundColor: "#E1E6E8",
                width: "100%",
                height: "1px",
                marginTop: "10px",
                marginBottom: "1px",
              }}
            ></Box>

            <Box
              sx={{
                paddingTop: "16px",
                paddingBottom: "16px",
                gap: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#70666C",
                }}
              >
                e-Reward ID
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#282427",
                }}
              >
                {data?.issueReward.id}
              </Typography>
            </Box>

            <Box
              sx={{
                backgroundColor: "#E1E6E8",
                width: "100%",
                height: "1px",
                marginTop: "10px",
                marginBottom: "1px",
              }}
            ></Box>

            <Box
              sx={{
                paddingTop: "16px",
                paddingBottom: "16px",
                gap: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#70666C",
                }}
              >
                Description
              </Typography>

              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#282427",
                }}
              >
                {data?.issueReward.ErewardDescription}
              </Typography>
            </Box>

            <Box
              sx={{
                backgroundColor: "#E1E6E8",
                width: "100%",
                height: "1px",
                marginTop: "10px",
                marginBottom: "1px",
              }}
            ></Box>

            <Box
              sx={{
                paddingTop: "16px",
                paddingBottom: "16px",
                gap: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#70666C",
                }}
              >
                Total value
              </Typography>

              <Box
                sx={{
                  alignSelf: "flex-start",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <img
                  src={require("../../assets/Rewardpoints.png")}
                  style={{ height: "18px", width: "18px" }}
                  alt={"Logo"}
                  loading="lazy"
                />
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "-0.2px",
                    color: "#282427",
                  }}
                >
                  {data?.issueReward?.totalPoints}pts
                </Typography>
              </Box>
              {/* <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>750pts</Typography> */}
            </Box>

            <Box
              sx={{
                backgroundColor: "#E1E6E8",
                width: "100%",
                height: "1px",
                marginTop: "10px",
                marginBottom: "1px",
              }}
            ></Box>

            <Box
              sx={{
                paddingTop: "16px",
                paddingBottom: "16px",
                gap: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#70666C",
                }}
              >
                Validity
              </Typography>

              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#282427",
                }}
              >
                {formatDate(data?.issueReward?.createdAt)} -{" "}
                {formatDate(data?.issueReward?.ExpirationDate)}
              </Typography>
            </Box>

            <Box
              sx={{
                backgroundColor: "#E1E6E8",
                width: "100%",
                height: "1px",
                marginTop: "10px",
                marginBottom: "1px",
              }}
            ></Box>

            <Box
              sx={{
                paddingTop: "16px",
                paddingBottom: "16px",
                gap: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#70666C",
                }}
              >
                Note to student
              </Typography>

              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#282427",
                }}
              >
                {data?.issueReward?.NoteToStudent}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}

export default Confirmation;
