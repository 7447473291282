import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { useQuery } from "@apollo/client";
import { GET_REWARD_LIST } from "../../GraphQL/Query";
import { rewardType } from "../type";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { getImgURL } from "../../utils";

const SearchIconWrapper = styled("div")(() => ({
  paddingRight: "9px",
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
export const NewEReward = () => {
  const { userData } = useContext(UserAuthContext);
  const { data, refetch } = useQuery(GET_REWARD_LIST, {
    variables: {
      email: userData?.email,
      limit: 7,
      skip: 0,
      orderby: "desc",
    },
  });

  const [searchedTerm, setSearchedTerm] = useState("");
  const [option, setOption] = useState<string[]>([]);
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState<any[]>([]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    val: string
  ) => {
    const filteredItem = checked.filter((item) => item.val !== val);
    if (event.target.checked === true) {
      setChecked([...filteredItem, { type: event.target.checked, val: val }]);
    } else {
      setChecked(filteredItem);
    }
  };
  const sortByOption = [
    {
      key: "Sort by name accending(A-Z)",
      val: "A-Z",
    },
    {
      key: "Sort by name decending (Z-A)",
      val: "Z-A",
    },
  ];
  const handleChangeSort = (event: SelectChangeEvent<typeof option>) => {
    const {
      target: { value },
    } = event;
    setOption(typeof value === "string" ? value.split(",") : value);
  };
  function itemExists(arr: any[], val: string) {
    return arr.some(function (el: any) {
      return el.val === val;
    });
  }

  useEffect(() => {
    if (option[0] === "Z-A") {
      refetch({ orderby: "desc" });
    } else {
      refetch({ orderby: "asc" });
    }
  }, [option]);
  return (
    <Stack>
      <Box sx={style.titleParent}>
        <Typography sx={style.rewardText}>New e-Rewards</Typography>
      </Box>

      <Box sx={{ height: "1px", backgroundColor: "#E1E6E8" }}></Box>
      <Box sx={style.searchParent}>
        <TextField
          required
          fullWidth
          sx={style.searchinput}
          placeholder={"Search"}
          onChange={(e) => setSearchedTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
              </>
            ),
          }}
        />
        <FormControl sx={style.formselect}>
          <Select
            value={option}
            onChange={handleChangeSort}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "-0.2px",
                  color: "#282427",
                }}
              >
                Sort
              </Typography>
            </MenuItem>
            {sortByOption.map((option) => (
              <MenuItem key={option.key} value={option.val}>
                {option.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box
        borderBottom={1}
        sx={{ marginTop: "16px" }}
        borderColor={"#E1E6E8"}
      ></Box>
      <Box
        sx={{
          height: "100vh",
          paddingTop: "24px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {data?.rewards
            .filter((reward: rewardType) => {
              return reward.RewardName.includes(searchedTerm);
            })
            .map((reward: any) => {
              return (
                <Grid item xs={6} key={reward?.id}>
                  <Box>
                    <label>
                    <Checkbox
                      checked={itemExists(checked, reward.id)}
                      onChange={(event) => handleChange(event, reward?.id)}
                      value={"s"}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ position: "absolute", color: "#CEC7CB" }}
                    />

                    <img
                      src={getImgURL(reward?.Media?.url)}
                      style={style.image}
                      alt={"coupon-svgrepo-com"}
                      loading="lazy"
                    />
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#282427",
                      }}
                    >
                      {reward?.RewardName}
                    </Typography>
                    <Box sx={style.pointParent}>
                      <img
                        src={require("../../assets/Rewardpoints.png")}
                        style={style.pointImg}
                        alt={"Logo"}
                        loading="lazy"
                      />
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                          letterSpacing: "-0.2px",
                          color: "#282427",
                        }}
                      >
                        {reward?.Points} pts
                      </Typography>
                    </Box>
                    </label>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Box>
      <Paper sx={style.buttomButtonParent} elevation={0}>
        <Box
          border={1}
          borderRadius={"4px"}
          borderColor={"#CEC7CB"}
          sx={style.cencelButton}
          onClick={() => navigate("/eRewards")}
        >
          <Typography sx={style.cencelText}>Cancel</Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "-0.2px",
            color: "#282427",
          }}
        >
          Selected: {checked?.length}
        </Typography>
        <Button
          type="submit"
          disabled={checked?.length > 0 ? false : true}
          onClick={() =>
            navigate("/IssueReward", {
              state: [checked],
            })
          }
          sx={
            checked?.length > 0
              ? style.publishParent
              : style.publishParentDisable
          }
        >
          <Typography style={style.publishText}>Next</Typography>
        </Button>
      </Paper>
    </Stack>
  );
};

const style = {
  main: {
    height: "100vh",
    width: "100vw",
    marginTop: "70px",
  },
  titleParent: {
    height: "56px",
    marginTop: "9px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "16px",
    paddingBottom: "16px",
  },
  rewardText: {
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "32px",
  },
  image: {
    width: "163.5px",
    height: "112px",
  },
  pointParent: {
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "2px",
  },
  pointImg: { height: "16px", width: "16px" },
  errorAndLoading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "16px",
  },
  // scroll: {
  //     marginTop: '16px'
  // },
  text: {
    display: "flex",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
    color: "#70666C",
  },
  buttomButtonParent: {
    borderTop: 1,
    borderColor: "#E1E6E8",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "77px",
    backgroundColor: "white",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  cencelButton: {
    height: "44px",
    width: "fit-content",
    paddingY: "10px",
    paddingX: "24px",
  },
  cencelText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
    color: "#282427",
  },
  publishParent: {
    borderRadius: "4px",
    height: "44px",
    width: "fit-content",
    backgroundColor: "#AD0067",
    paddingY: "10px",
    paddingX: "24px",
    alignItems: "center",
    display: "flex",
    gap: "6px",
  },
  publishParentDisable: {
    borderRadius: "4px",
    height: "44px",
    width: "fit-content",
    backgroundColor: "#2824271F",
    paddingY: "10px",
    paddingX: "24px",
    alignItems: "center",
    display: "flex",
    gap: "6px",
  },
  publishText: {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
  },
  searchParent: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    marginTop: "16px",
    gap: "8px",
    marginRight: "16px",
    marginLeft: "16px",
  },
  searchinput: {
    height: "48px",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    justifySelf: "center",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.35px",
  },
  formselect: { m: 1, width: "130px" },
};
