import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material"
import { GET_ISSUED_REWARD_LIST } from "../../GraphQL/Query";
import { formatDate } from "../../component/utils"
import { useNavigate } from "react-router-dom";
export const IssuedRewardListItem = ({ studentReward }: any) => {

    const id = studentReward?.id
    const navigate = useNavigate();
    return (<>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: 'auto',
                paddingLeft: '16px',
                paddingRight: '16px'

            }}
            key={studentReward?.id}
            onClick={() => navigate(`/IssueRewardDetails/${id}`)}
        >
            <Box sx={{ width: '100%', paddingTop: '16px', paddingBottom: '16px', gap: '4px', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', width: '100%', }}>
                    <Typography sx={{ fontWeight: '400', fontSize: '15px', lineHeight: '24px', letterSpacing: '-0.2px', color: '#666064' }}>{studentReward?.receipientEmail}</Typography>
                    <Typography
                        sx={{ fontWeight: '400', fontSize: '15px', lineHeight: '24px', letterSpacing: '-0.2px', color: '#666064' }}>{formatDate(studentReward?.createdAt)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', letterSpacing: '0.2px', color: '#282427' }}>{studentReward?.ErewardId}</Typography>
                    <Box sx={style.pointParent}>
                        <img
                            src={require('../../assets/Rewardpoints.png')}
                            style={style.pointImg}
                            alt={'Logo'}
                            loading="lazy"
                        />
                        <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', letterSpacing: '-0.2px', color: '#282427' }}>{studentReward?.totalPoints} points</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px', paddingTop: '4px', paddingBottom: '4px' }}>
                    {studentReward?.Status?.map((status: string) => {
                        if (status === 'Pending') {
                            return (<Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', backgroundColor: '#F9D9A4', width: 'auto', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center', borderRadius: '100px' }}>
                                <Box
                                    border={2}
                                    sx={{
                                        height: '9px', width: '9px',
                                        borderRadius: "100%",
                                        borderColor: '#7C5C00'
                                    }}></Box>
                                <Typography
                                    sx={{
                                        display: 'flex', fontWeight: '400', fontSize: '13px', lineHeight: '18px', letterSpacing: '0.2px',
                                        color: '#282427'
                                    }}
                                >Pending</Typography>
                            </Box>)
                        }
                        if (status === 'Active') {
                            return (<Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', backgroundColor: '#F9D9A4', width: 'auto', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center', borderRadius: '100px' }}>
                                <Box
                                    border={2}
                                    sx={{
                                        height: '9px', width: '9px',
                                        borderRadius: "100%",
                                        borderColor: '#7C5C00'
                                    }}></Box>
                                <Typography
                                    sx={{
                                        display: 'flex', fontWeight: '400', fontSize: '13px', lineHeight: '18px', letterSpacing: '0.2px',
                                        color: '#282427'
                                    }}
                                >Active</Typography>
                            </Box>)
                        }
                        if (status === 'Expired') {
                            return (<Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', backgroundColor: '#FC9797', width: 'auto', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center', borderRadius: '100px' }}>
                                <Box sx={{ height: '9px', width: '9px', backgroundColor: '#7F2223', borderRadius: "100%", borderWidth: '2px', borderColor: '#7C5C00' }}></Box>
                                <Typography
                                    sx={{ display: 'flex', fontWeight: '400', fontSize: '13px', lineHeight: '18px', letterSpacing: '0.2px', color: '#282427' }}
                                >Expired</Typography>
                            </Box>)
                        }
                        if (status === 'Redeemed') {
                            return (<Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', backgroundColor: '#BAE7D2', width: 'auto', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center', borderRadius: '100px' }}>
                                <Box
                                    border={2}
                                    sx={{
                                        height: '9px', width: '9px',
                                        borderRadius: "100%",
                                        borderWidth: '2px',
                                        backgroundColor: '#036F50',
                                        borderColor: '#036F50'
                                    }}></Box>
                                <Typography
                                    sx={{
                                        display: 'flex', fontWeight: '400', fontSize: '13px', lineHeight: '18px', letterSpacing: '0.2px',
                                        color: '#282427'
                                    }}
                                >Redeemed</Typography>
                            </Box>)
                        }
                    })}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', }}>
                    <Typography sx={{ fontWeight: '400', fontSize: '15px', lineHeight: '24px', letterSpacing: '-0.2px', color: '#666064' }}>No. of reward items {studentReward?.IssueRewardsItemCount} </Typography>
                </Box>
            </Box>
        </Box></>)
};



const style = {
    main: {
        height: '100vh', width: '100vw',
        marginTop: '70px',
    },
    titleParent: {
        height: "56px",
        marginTop: '9px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '16px',
        paddingBottom: '16px',
    },
    rewardText: {
        fontWeight: '500',
        fontSize: '22px',
        lineHeight: '32px'
    },
    itemList: {
        position: 'fixed',
        bottom: 21,
        right: 20,
    },
    flotingButtonIcon: { height: 60, width: 60 },
    searchParent: { display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginTop: '16px', gap: '8px', marginRight: '16px', marginLeft: '16px' },
    searchinput: {
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        justifySelf: 'center',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.35px',
    },
    formselect: { m: 1, width: '130px' },
    pointImg: { height: "16px", width: "16px" },
    pointParent: { alignSelf: 'flex-start', display: 'flex', flexDirection: 'row', alignItems: 'center' }
}