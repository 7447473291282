import {
  Alert,
  Button,
  Paper,
  Snackbar,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_REWARD } from "../../GraphQL/Mutation";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const validationSchema = yup.object({
  rewardname: yup.string().required("Reward name is required."),
  rewarddescription: yup.string().required("Reward description is required."),
  image: yup.object(),
  rewardvalue: yup.string(),
  quantity: yup.number(),
});

export const Rewardcreate = () => {
  const [image, setImage] = useState<any>();
  const { userData } = useContext(UserAuthContext);
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const navigate = useNavigate();
  const [
    createReward,
    {
      data: createRewardData,
      error: createRewardError,
      loading: createRewardLoading,
    },
  ] = useMutation(CREATE_REWARD);

  const formik = useFormik({
    initialValues: {
      rewardname: "",
      rewarddescription: "",
      image: {
        preview: "",
        raw: "",
      },
      rewardvalue: "",
      quantity: 0,
      file: undefined,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      createReward({
        variables: {
          rewardname: values.rewardname,
          rewarddescription: values.rewarddescription,
          points: parseInt(values.rewardvalue),
          qty: parseInt(values.quantity),
          image: values.file,
          email: userData?.email,
        },
      });
    },
  });

  useEffect(() => {
    if (createRewardData) {
      setOpen(true);
      setTimeout(function () {
        navigate("/rewardlist");
      }, 800);
    }
    if (createRewardError) {
      formik.resetForm();
      setErrorOpen(true)
    }
  }, [createRewardData, createRewardError]);

  return (
    <>
      <Stack sx={style.main}>
        <Box sx={style.titleParent} borderBottom={1} borderColor={"#E1E6E8"}>
          <Typography sx={style.title}> New reward item</Typography>
        </Box>

        <Box sx={style.formParent}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={style.formInputParent}>
              <Box sx={style.inputSec1}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      value={formik.values.rewardname}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.rewardname &&
                        Boolean(formik.errors.rewardname)
                      }
                      helperText={
                        formik.touched.rewardname && formik.errors.rewardname
                      }
                      required
                      id="rewardname"
                      name="rewardname"
                      label="Item name"
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: "-16px" }}
                      inputProps={{
                        maxLength: 60,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Typography
                      color={
                        formik.values.rewardname.length > 60
                          ? "error"
                          : "textSecondary"
                      }
                      variant="caption"
                    >
                      {formik.values.rewardname.length}/60
                    </Typography>
                  </Grid>
                </Grid>
                <TextField
                  id="rewarddescription"
                  name="rewarddescription"
                  value={formik.values.rewarddescription}
                  onChange={formik.handleChange}
                  multiline
                  minRows={3}
                  // placeholder="Item description"
                  error={
                    formik.touched.rewarddescription &&
                    Boolean(formik.errors.rewarddescription)
                  }
                  helperText={
                    formik.touched.rewarddescription && formik.errors.rewarddescription
                  }
                  required
                  label="Item description"
                  variant="outlined"
                  fullWidth
                />
              </Box>

              <Box sx={style.divider}></Box>

              <Box sx={style.inputSec2}>
                <Typography sx={style.rewardDetails}>Item details</Typography>
                <Box
                  sx={{
                    border: 1,
                    marginTop: "16px",
                    borderColor: "#E1E6E8",
                    width: "100%",
                  }}
                ></Box>
                <Typography sx={style.mediaText}>Media</Typography>

                {/* <Box sx={style.fileUploadParent} >
                                {<><label htmlFor="image">
                                {image && <img
                                    src={image}

                                    style={{ height: 130, }}
                                    alt={'Logo'}
                                    loading="lazy"
                                    color='red'
                                />}
                                   {!image &&  <Typography sx={style.uploadImgText}>Upload image</Typography>}
                                </label>
                                </>}

                                <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    required
                                    style={style.file}
                                    onChange={(event) => {
                                        if (event?.target?.files) {
                                            setImage(URL.createObjectURL(event?.target.files[0]))
                                            formik.setFieldValue("file", event?.target?.files[0])
                                        }
                                    }}
                                />
                            </Box> */}

                <Box sx={{ position: "relative", ...style.fileUploadParent }}>
                  {image ? (
                    <>
                      <label htmlFor="image">
                        <img
                          src={image}
                          style={{
                            height: 130,
                            display: "flex",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                          alt="Logo"
                          loading="lazy"
                          color="red"
                        />
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            cursor: "pointer",
                            ...style.uploadImgText,
                          }}
                        >
                          Change Image
                        </Typography>
                      </label>
                    </>
                  ) : (
                    <>
                      <label htmlFor="image">
                        <Typography sx={style.uploadImgText}>
                          Upload image
                        </Typography>
                      </label>
                    </>
                  )}
                  <input
                    type="file"
                    id="image"
                    name="image"
                    required
                    style={style.file}
                    onChange={(event) => {
                      if (event?.target?.files) {
                        setImage(URL.createObjectURL(event?.target.files[0]));
                        formik.setFieldValue("file", event?.target?.files[0]);
                      }
                    }}
                  />
                </Box>

                <Box sx={style.divider1}></Box>

                <Typography sx={style.valueText}>Value</Typography>
                <Typography sx={style.valueDesc}>
                  This will be deducted from the student’s total accumulated
                  points
                </Typography>
                <TextField
                  sx={style.rewardvalInput}
                  value={formik.values.rewardvalue}
                  onChange={formik.handleChange}
                  type="number"
                  error={
                    formik.touched.rewardvalue &&
                    Boolean(formik.errors.rewardvalue)
                  }
                  helperText={
                    formik.touched.rewardvalue && formik.errors.rewardvalue
                  }
                  required
                  id="rewardvalue"
                  name="rewardvalue"
                  label="Reward points"
                  variant="outlined"
                  fullWidth
                />

                <Box sx={style.divider1}></Box>

                <Typography sx={style.qtyText}>Available quantity</Typography>
                <TextField
                  sx={style.rewardvalInput}
                  value={formik.values.quantity}
                  type="number"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.quantity && Boolean(formik.errors.quantity)
                  }
                  helperText={formik.touched.quantity && formik.errors.quantity}
                  required
                  id="quantity"
                  name="quantity"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <Box sx={{ textAlign: "right" }}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "quantity",
                              Number(formik.values.quantity) + 1
                            )
                          }
                          sx={{
                            width: 24,
                            height: 14,
                            backgroundColor: "#E4E5E7",
                            borderRadius: 1,
                          }}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "quantity",
                              Math.max(Number(formik.values.quantity) - 1, 0)
                            )
                          }
                          sx={{
                            width: 24,
                            height: 14,
                            backgroundColor: "#E4E5E7",
                            borderRadius: 1,
                          }}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </Box>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Paper sx={style.buttomButtonParent} elevation={0}>
              <Box
                onClick={() => navigate(-1)}
                border={1}
                borderRadius={"4px"}
                borderColor={"#CEC7CB"}
                sx={style.cencelButton}
              >
                <Typography sx={style.cencelText}>Cancel</Typography>
              </Box>
              {/* <Button
                            type='submit'
                            sx={style.publishParent}>
                            <Typography sx={{ textTransform: 'none' }} style={style.publishText}>Publish</Typography>
                        </Button> */}
              <LoadingButton
                type="submit"
                loading={createRewardLoading}
                loadingPosition="center"
                sx={style.publishParent}
                variant="contained"
                disabled={
                formik.values.rewardname &&
                formik.values.rewarddescription &&
                formik.values.rewardvalue &&
                formik.values.file &&
                formik.values.quantity
                  ? false
                  : true
                }
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "-0.2px",
                    textTransform: "none",
                  }}
                >
                  Publish
                </Typography>
              </LoadingButton>
            </Paper>
          </form>
        </Box>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            variant="standard"
            sx={{ width: "100%" }}
            severity="success"
          >
            New reward created!
          </Alert>
        </Snackbar>
        <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            variant="standard"
            sx={{ width: "100%" }}
            severity="error"
          >
           {createRewardError?.message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

const style = {
  main: {
    height: "100vh",
    width: "100vw",
    // marginTop: '70px',
  },
  titleParent: {
    height: "56px",
    marginTop: "9px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "16px",
    paddingBottom: "16px",
  },
  title: {
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "32px",
  },
  formParent: { marginTop: "24px", height: "auto", width: "100vw" },
  formInputParent: {
    height: "100vh",
    width: "100vw",
    marginBottom: "100px",
  },
  inputSec1: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  textareaStyle: {
    borderRadius: "4px",
    borderColor: "#CEC7CB",
    borderWidth: "1px",
    width: "100%",
    paddingLeft: "16px",
    paddingTop: "16px",
    paddingRight: "16px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.35px",
  },
  divider: {
    border: 1,
    marginTop: "24px",
    borderColor: "#E1E6E8",
    width: "100%",
  },
  inputSec2: { marginTop: "24px", paddingLeft: "16px", paddingRight: "16px" },
  rewardDetails: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "36px",
    letterSpacing: "-0.2px",
    color: "#282427",
  },
  mediaText: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.2px",
    color: "#282427",
    marginTop: "16px",
  },
  fileUploadParent: {
    border: 1,
    marginTop: "16px",
    borderColor: "#CEC7CB",
    width: "100%",
    height: "130px",
    borderStyle: "dotted",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadImgText: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "4px",
    paddingBottom: "4px",
    backgroundColor: "#F2F6FB",
    color: "#0045B7",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.2px",
  },
  file: { display: "none" },
  divider1: {
    border: 1,
    marginTop: "16px",
    borderColor: "#E1E6E8",
    width: "100%",
  },
  valueText: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.2px",
    color: "#282427",
    marginTop: "16px",
  },
  valueDesc: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#70666C",
    marginTop: "4px",
  },
  rewardvalInput: { marginTop: "16px" },
  qtyText: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.2px",
    color: "#282427",
    marginTop: "16px",
  },
  buttomButtonParent: {
    borderTop: 1,
    borderColor: "#E1E6E8",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "77px",
    backgroundColor: "white",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  cencelButton: {
    height: "44px",
    width: "fit-content",
    paddingY: "10px",
    paddingX: "24px",
  },
  cencelText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
    color: "#282427",
  },
  publishParent: {
    borderRadius: "4px",
    height: "44px",
    width: "fit-content",
    backgroundColor: "#AD0067",
    paddingY: "10px",
    paddingX: "24px",
    alignItems: "center",
    display: "flex",
    gap: "6px",
  },
  publishText: {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
  },
};
