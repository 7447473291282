import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_REWARD_TO_ASSIGN } from "../../GraphQL/Query";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ISSUE_REWARD_TO_STUDENT,
  UPDATE_QR_IMAGE,
} from "../../GraphQL/Mutation";
import { DesktopDatePicker } from "@mui/x-date-pickers";
// @ts-ignore
import QRCode from "qrcode";
import { getImgURL } from "../../utils";

export const IssueReward = () => {
  const { userData } = useContext(UserAuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [CreateIssueReward, { data: issueRewardData }] = useMutation(
    ISSUE_REWARD_TO_STUDENT
  );
  const [UpdateQr, { data: UpdateQrData, error: UpdateQrError }] =
    useMutation(UPDATE_QR_IMAGE);

  const [getData, { data }] = useLazyQuery(GET_REWARD_TO_ASSIGN, {
    variables: {
      idArr: location?.state[0].map(
        (item: { type: boolean; val: string }) => item.val
      ),
    },
    fetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (
      location?.state[0].map((item: { type: boolean; val: string }) => item.val)
        ?.length > 0
    ) {
      getData();
    }
  }, [location?.state[0]]);
  const [points, setPoints] = useState<any[]>([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [isExpirationDate, setIsExpirationDate] = useState(false);
  const [checked] = React.useState<any[]>([]);

  let emailExt = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const validationSchema = yup.object({
    eRewardName: yup.string(),
    eRewardDescription: yup.string(),
    startdate: yup.date(),
    expirydate: yup.date(),
    studentEmail: yup
      .string()
      .matches(emailExt, "Enter a valid email.")
      .email("Enter a valid email")
      .required("Email is required"),
    notToStudent: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      eRewardName: "",
      eRewardDescription: "",
      //startdate: new Date(),
      expirydate: new Date(),
      studentEmail: "",
      notToStudent: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
  
      var rewardItemArr: any[] = [];
      points?.map((point) => {
        let obj = {
          rewards: { connect: { id: point.rewardId } },
          Qty: point.qty.toString(),
        };
        rewardItemArr.push(obj);
      });
      CreateIssueReward({
        variables: {
          rewardItem: { create: rewardItemArr },
          rewardId: values.eRewardName,
          //StartDate: values.startdate,
          createdAt: new Date(),
          ExpirationDate: values.expirydate,
          ErewardDescription: values.eRewardDescription,
          receipientEmail: values.studentEmail,
          NoteToStudent: values.notToStudent,
          totalPoints: totalPoints,
          Sponsor: userData?.email,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
    },
  });
 
  useEffect(() => {
    if (points?.length > 0) {
      setTotalPoints(
        points
          ?.map((item) => item?.totalRewardPerItem)
          .reduce((prev, next) => prev + next)
      );
    }
  }, [points]);

  useEffect(() => {
    if (issueRewardData) {
      QRCode.toDataURL(issueRewardData?.createIssueReward?.id)
        .then((url: any) => {
          fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File(
                [blob],
                issueRewardData?.createIssueReward?.id,
                { type: "image/png" }
              );
              UpdateQr({
                variables: {
                  id: issueRewardData?.createIssueReward?.id,
                  image: file,
                },
              });
            });
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  }, [issueRewardData]);

  useEffect(() => {
    if (UpdateQrData) {
      navigate("/Confirmation", {
        state: issueRewardData,
      });
    }
    if (UpdateQrError) {
      alert("Somthing went wrong!");
    }
  }, [UpdateQrData, UpdateQrError]);

  return (
    <Stack>
      <Box
        sx={{
          paddingTop: "23px",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "24px",
        }}
      >
        <Box
          sx={{
            height: "40px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500px",
              fontSize: "18px",
              lineHeight: "28px",
              letterSpacing: "-0.15px",
              color: "#282427",
            }}
          >
            Rewards
          </Typography>
          <Typography
            sx={{
              textDecoration: "underline",
              fontWeight: "500px",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#0045B7",
              letterSpacing: "-0.2px",
            }}
            onClick={() => navigate(-1)}
          >
            Add more
          </Typography>
        </Box>

        {data?.rewards?.map((reward: any) => {
          return (
            <>
              <Box
                key={reward?.id}
                sx={{ marginTop: "12px", display: "flex", gap: "16px" }}
              >
                <Box>
                  <img
                    src={getImgURL(reward?.Media?.url)}
                    style={style.image}
                    alt={"coupon-svgrepo-com"}
                    loading="lazy"
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      fontWeight: "500",
                      fontSize: "16px",

                      lineHeight: "24px",
                      letterSpacing: "0.2px",
                      color: "#282427",
                    }}
                  >
                    <Box>{reward?.RewardName}</Box>
                    <Box>
                      <img
                        src={require("../../assets/delete.png")}
                        style={{ width: "21.6px", height: "21.6px" }}
                        alt={"coupon-svgrepo-com"}
                        loading="lazy"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Box>
                      <TextField
                        id="outlined-basic"
                        label="Qty"
                        type={"number"}
                        onChange={(e) => {
                          const filteredItemPoints = points.filter(
                            (item) => item.reward !== reward?.RewardName
                          );
                          setPoints([
                            ...filteredItemPoints,
                            {
                              rewardId: reward?.id,
                              reward: reward?.RewardName,
                              qty: e.target.value
                                ? parseInt(e.target.value)
                                : 0,
                              pointPerSingleReward: reward?.Points,
                              totalRewardPerItem:
                                parseInt(reward?.Points) *
                                (e.target.value ? parseInt(e.target.value) : 0),
                            },
                          ]);
                        }}
                        variant="outlined"
                        sx={{ width: "71px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <img
                        src={require("../../assets/Rewardpointsblack.png")}
                        style={{
                          width: "14px",
                          height: "14px",
                          display: "flex",
                        }}
                        alt={"coupon-svgrepo-com"}
                        loading="lazy"
                      />
                      <Typography
                        sx={{
                          display: "flex",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      >
                        {reward?.Points} pts
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          );
        })}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "12px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "28px",
              letterSpacing: "0.1px",
              color: "#282427",
            }}
          >
            Total
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <img
              src={require("../../assets/Rewardpoints.png")}
              style={style.pointImg}
              alt={"Logo"}
              loading="lazy"
            />
            <Typography
              sx={{
                fontWeight: "600px",
                fontSize: "18px",
                lineHeight: "28px",
                letterSpacing: "0.1",
                color: "#282427",
              }}
            >
              {totalPoints} pts
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#E1E6E8", height: "1px" }}></Box>

      <Box
        sx={{ paddingTop: "24px", paddingLeft: "16px", paddingRight: "16px" }}
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "28px",
            letterSpacing: "-0.15px",
          }}
        >
          e-Reward ID
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "16px",
            paddingBottom: "100px",
          }}
        >
          <TextField
            fullWidth
            id="eRewardName"
            name="eRewardName"
            label="e-Reward name"
            required
            value={formik.values.eRewardName}
            onChange={formik.handleChange}
            error={
              formik.touched.eRewardName && Boolean(formik.errors.eRewardName)
            }
            helperText={formik.touched.eRewardName && formik.errors.eRewardName}
          />
          <TextField
            fullWidth
            id="eRewardDescription"
            name="eRewardDescription"
            label="e-Reward description"
            required
            multiline
            minRows={3}
            style={{ marginTop: "20px" }}
            value={formik.values.eRewardDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.eRewardDescription &&
              Boolean(formik.errors.eRewardDescription)
            }
            helperText={
              formik.touched.eRewardDescription &&
              formik.errors.eRewardDescription
            }
          />
          <Box
            sx={{
              backgroundColor: "#E1E6E8",
              height: "1px",
              marginTop: "24px",
            }}
          ></Box>
          <Box sx={{ marginTop: "24px" }}>
            <Typography>Validity</Typography>
            <RadioGroup
              aria-labelledby="Validity"
              defaultValue="No expiration date set"
              name="Validity"
              onChange={(e) => {
                if (e.target.value === "No expiration date set") {
                  setIsExpirationDate(false);
                }
                if (e.target.value === "Set expiration date") {
                  setIsExpirationDate(true);
                }
              }}
            >
              <FormControlLabel
                value="No expiration date set"
                control={<Radio />}
                label="No expiration date set"
              />
              <FormControlLabel
                value="Set expiration date"
                control={<Radio />}
                label="Set expiration date"
              />
            </RadioGroup>
            {isExpirationDate && (
              <Box
                sx={{
                  marginTop: "16px",
                }}
              >
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Start Date"
                    value={formik.values.startdate}
                    onChange={(value) =>
                      formik.setFieldValue("startdate", value)
                    }
                    renderInput={(params) => (
                      <TextField {...params} style={{ width: "100%" }} />
                    )}
                  />
                </LocalizationProvider> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Expiration Date"
                    value={formik.values.expirydate}
                    onChange={(value) =>
                      formik.setFieldValue("expirydate", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "100%"}}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              backgroundColor: "#E1E6E8",
              height: "1px",
              marginTop: "24px",
            }}
          ></Box>

          <Box sx={{ marginTop: "16px" }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "28px",
                letterSpacing: "-0.15px",
                color: "#282427",
              }}
            >
              Recipient
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#70666C",
              }}
            >
              Type the email address of the student that will receive this
              coupon
            </Typography>
            <TextField
              fullWidth
              id="studentEmail"
              name="studentEmail"
              label="Email address"
              type="email"
              required
              style={{ marginTop: "16px" }}
              value={formik.values.studentEmail}
              onChange={formik.handleChange}
              error={
                formik.touched.studentEmail &&
                Boolean(formik.errors.studentEmail)
              }
              helperText={
                formik.touched.studentEmail && formik.errors.studentEmail
              }
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "#E1E6E8",
              height: "1px",
              marginTop: "24px",
            }}
          ></Box>
          <Typography
            sx={{
              marginTop: "24px",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "28px",
              letterSpacing: "-0.15px",
              color: "#282427",
            }}
          >
            Note to student
          </Typography>
          <TextField
            fullWidth
            id="notToStudent"
            name="notToStudent"
            label="Message"
            type="text"
            style={{ marginTop: "16px" }}
            value={formik.values.notToStudent}
            onChange={formik.handleChange}
            error={
              formik.touched.notToStudent && Boolean(formik.errors.notToStudent)
            }
            helperText={
              formik.touched.notToStudent && formik.errors.notToStudent
            }
          />
          <Paper sx={style.buttomButtonParent} elevation={0}>
            <Button
              type="submit"
              onClick={() =>
                navigate("/IssueReward", {
                  state: [checked],
                })
              }
              disabled={
                formik.values.eRewardDescription &&
                formik.values.eRewardName &&
                formik.values.notToStudent &&
                formik.values.studentEmail
                  ? false
                  : true
              }
              sx={
                formik.values.eRewardDescription &&
                formik.values.eRewardName &&
                formik.values.notToStudent &&
                formik.values.studentEmail
                  ? style.publishParent
                  : style.publishParentDisable
              }
            >
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "-0.2px",
                }}
              >
                Publish
              </Typography>
            </Button>
          </Paper>
        </form>
      </Box>
    </Stack>
  );
};

const style = {
  main: {
    height: "100vh",
    width: "100vw",
    marginTop: "70px",
  },
  titleParent: {
    height: "56px",
    marginTop: "9px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "16px",
    paddingBottom: "16px",
  },
  rewardText: {
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "32px",
  },
  image: {
    width: "44px",
    height: "44px",
    borderRadius: "4px",
  },
  pointParent: {
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  pointImg: { height: "14.17px", width: "16.67px" },
  errorAndLoading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "16px",
  },
  text: {
    display: "flex",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
    color: "#70666C",
  },
  buttomButtonParent: {
    borderTop: 1,
    borderColor: "#E1E6E8",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "77px",
    backgroundColor: "white",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  cencelButton: {
    height: "44px",
    width: "fit-content",
    paddingY: "10px",
    paddingX: "24px",
  },
  cencelText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
    color: "#282427",
  },
  publishParent: {
    marginLeft: "auto",
    borderRadius: "4px",
    height: "44px",
    width: "fit-content",
    backgroundColor: "#AD0067",
    paddingY: "10px",
    paddingX: "24px",
    alignItems: "center",
    display: "flex",
    gap: "6px",
  },
  publishParentDisable: {
    marginLeft: "auto",
    borderRadius: "4px",
    height: "44px",
    width: "fit-content",
    backgroundColor: "#2824271F",
    paddingY: "10px",
    paddingX: "24px",
    alignItems: "center",
    display: "flex",
    gap: "6px",
  },
  publishText: {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
  },
};
