import { Typography, Stack, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

export const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#142A50",
          height: "64px",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      />
      <Box
        sx={{
          display: "flex",
          height: "auto",
          paddingTop: "20px",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "32px",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "32px",
            letterSpacing: "-0.2px",
          }}
        >
          e-Rewards List
        </Typography>
        <Typography sx={{ marginTop: "4px" }}>
          Description text for viewing and creating reward list.
        </Typography>

        <Button
          sx={{ marginTop: "16px", width: "fit-content" }}
          variant="outlined"
          startIcon={
            <img
              src={require("../../assets/Giftbox-active.png")}
              style={{ height: 20, width: 20 }}
              alt={"Logo"}
              loading="lazy"
            />
          }
          onClick={() => navigate("/rewardlist")}
        >
          View your reward list
        </Button>
      </Box>

      <Box
        sx={{
          width: "auto",
          height: "1px",
          backgroundColor: "#E1E6E8",
        }}
      />

      <Box
        sx={{
          display: "flex",
          height: "auto",
          paddingTop: "20px",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "32px",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "32px",
            letterSpacing: "-0.2px",
          }}
        >
          e-Rewards Distribution
        </Typography>
        <Typography sx={{ marginTop: "4px" }}>
          Description text for how to get started with existing and creating new
          e-Rewards.
        </Typography>

        <Button
          sx={{ marginTop: "16px", width: "fit-content" }}
          variant="outlined"
          startIcon={
            <img
              src={require("../../assets/coupon-svgrepo-com.png")}
              style={{ height: 20, width: 20 }}
              alt={"Logo"}
              loading="lazy"
            />
          }
          onClick={() => navigate("/eRewards")}
        >
          View your e-Rewards
        </Button>
      </Box>
      <Box
        sx={{
          width: "auto",
          height: "1px",
          backgroundColor: "#E1E6E8",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          height: "auto",
          paddingTop: "20px",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "32px",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "32px",
            letterSpacing: "-0.2px",
          }}
        >
          e-Rewards Redemption
        </Typography>
        <Typography sx={{ marginTop: "4px" }}>
          Description text for Scanning QR code attached to e-Rewards.
        </Typography>

        <Button
          sx={{ marginTop: "16px", width: "fit-content" }}
          variant="outlined"
          startIcon={<QrCodeScannerIcon />}
          onClick={() => navigate("/qrscan")}
        >
          Scan QR code
        </Button>
      </Box>
    </Stack>
  );
};
