import { useMutation } from "@apollo/client";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { AUTH_MUTATION, updateEmail } from "../../GraphQL/Mutation";

const validationSchema = yup.object({
  currentPassword: yup.string().required("Current password is required."),
  newEmail: yup.string().required("New email is required."),
});

function EditEmail() {
  const [updated, setUpdated] = useState(false);
  const [updateNewEmail, { loading, data }] = useMutation(updateEmail);
  const [
    checkoldPassword,
    {
      loading: checkoldPasswordLoading,
      data: checkoldPasswordData,
      error: checkoldPasswordError,
    },
  ] = useMutation(AUTH_MUTATION);
  const { userData } = useContext(UserAuthContext);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newEmail: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      checkoldPassword({
        variables: {
          email: userData?.email,
          password: formik.values.currentPassword,
        },
      });
    },
  });

  useEffect(() => {
    if (checkoldPasswordData) {
      if (
        checkoldPasswordData?.authenticateUserWithPassword?.__typename !==
        "UserAuthenticationWithPasswordFailure"
      ) {
        updateNewEmail({
          variables: {
            newEmail: formik.values.newEmail,
            currentEmail: userData?.email,
          },
        });
      } else {
        alert("Current passowrd is invalid!");
      }
    }
    if (checkoldPasswordError) {
      alert("Somthing went wrong!");
    }
  }, [
    checkoldPasswordData,
    checkoldPasswordError,
    updateNewEmail,
    formik.values.newEmail,
    userData?.email,
  ]);

  useEffect(() => {
    if (data) {
      setUpdated(true);
    }
  }, [data]);

  return (
    <Stack>
      <Box
        sx={{
          paddingTop: "16px",
          paddingLeft: "16px",
          paddingRight: "16px",
          gap: "4px",
          paddingBottom: "16px",
        }}
      >
        <Typography
          sx={{ fontWeight: "500", fontSize: "22px", lineHeight: "32px" }}
        >
          Update email
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "24px",
            letterSpacing: "0.2px",
            color: "#70666C",
          }}
        >
          Current: {userData?.email}
        </Typography>
      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "16px",
          display: "flex",
          gap: "20px",
          flexDirection: "column",
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          style={{ gap: "20px", display: "flex", flexDirection: "column" }}
        >
          <TextField
            required
            value={formik.values.currentPassword}
            fullWidth
            // }}
            onChange={formik.handleChange}
            error={
              formik.touched.currentPassword &&
              Boolean(formik.errors.currentPassword)
            }
            helperText={
              formik.touched.currentPassword && formik.errors.currentPassword
            }
            id="currentPassword"
            name="currentPassword"
            label={"Current password"}
            type={values.showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? (
                      <VisibilityOff color={"secondary"} />
                    ) : (
                      <Visibility color={"secondary"} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            value={formik.values.newEmail}
            fullWidth
            // }}
            onChange={formik.handleChange}
            error={formik.touched.newEmail && Boolean(formik.errors.newEmail)}
            helperText={formik.touched.newEmail && formik.errors.newEmail}
            id="newEmail"
            name="newEmail"
            label={"New email"}
            // type={values.showPassword ? 'text' : 'password'}
            // InputProps={{
            //     endAdornment: (
            //         <InputAdornment position='end'>
            //             <IconButton
            //                 aria-label='toggle password visibility'
            //                 onClick={handleClickShowPassword}
            //                 onMouseDown={handleMouseDownPassword}>
            //                 {values.showPassword ? <VisibilityOff color={'secondary'} /> : <Visibility color={'secondary'} />}
            //             </IconButton>
            //         </InputAdornment>
            //     ),
            // }}
          />
          {/* <TextField
                        required
                        value={confirmPassword}
                        fullWidth
                        // }}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        id="confirmPassword" name="confirmPassword"
                        label={'Confirm password'}
                        type={values.showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}>
                                        {values.showPassword ? <VisibilityOff color={'secondary'} /> : <Visibility color={'secondary'} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    /> */}
          <Paper sx={style.buttomButtonParent} elevation={0}>
            {/* <Box border={1} borderRadius={'4px'} borderColor={'#CEC7CB'} sx={style.cencelButton}>
                    <Typography sx={style.cencelText}>Cancel</Typography>
                </Box> */}
            <LoadingButton
              type="submit"
              loading={loading || checkoldPasswordLoading}
              loadingPosition="center"
              // disabled={data?.issueReward?.Status?.includes('Expired') || redeemed || redeemrewardsLoading ? true : false}
              disabled={updated ?? loading ?? checkoldPasswordLoading}
              // style={{ marginTop: '16px' }}
              // onClick={() => redeem()}
              variant="contained"
              disableElevation
              fullWidth
              sx={
                !formik.values.newEmail || !formik.values.currentPassword
                  ? {
                      borderRadius: "4px",
                      height: "44px",
                      width: "fit-content",
                      backgroundColor: "#2824271F",
                      paddingY: "10px",
                      paddingX: "24px",
                      alignItems: "center",
                      display: "flex",
                      gap: "6px",
                      marginLeft: "auto",
                    }
                  : {
                      borderRadius: "4px",
                      height: "44px",
                      width: "fit-content",
                      backgroundColor: "#AD0067",
                      paddingY: "10px",
                      paddingX: "24px",
                      alignItems: "center",
                      display: "flex",
                      gap: "6px",
                      marginLeft: "auto",
                    }
              }
            >
              Update
            </LoadingButton>
            {/* <LoadingButton
                    type='submit'
                    onClick={() => redeem()}
                    border={1} borderRadius={'4px'} borderColor={'#CEC7CB'} sx={redeemrewardsData ? { borderRadius: '4px', height: '44px', width: "fit-content", backgroundColor: '#2824271F', paddingY: '10px', paddingX: '24px', alignItems: 'center', display: 'flex', gap: '6px', marginLeft: 'auto' } : { borderRadius: '4px', height: '44px', width: "fit-content", backgroundColor: '#AD0067', paddingY: '10px', paddingX: '24px', alignItems: 'center', display: 'flex', gap: '6px', marginLeft: 'auto' }}>
                    <Typography sx={style.publishText}>Update</Typography>
                </LoadingButton> */}
          </Paper>
        </form>
      </Box>
    </Stack>
  );
}

export default EditEmail;

const style = {
  buttomButtonParent: {
    borderTop: 1,
    borderColor: "#E1E6E8",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "77px",
    backgroundColor: "white",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  cencelButton: {
    height: "44px",
    width: "fit-content",
    paddingY: "10px",
    paddingX: "24px",
  },
  cencelText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
    color: "#282427",
  },
  publishParent: {
    borderRadius: "4px",
    height: "44px",
    width: "fit-content",
    backgroundColor: "#AD0067",
    paddingY: "10px",
    paddingX: "24px",
    alignItems: "center",
    display: "flex",
    gap: "6px",
  },
  publishParentDisable: {
    borderRadius: "4px",
    height: "44px",
    width: "fit-content",
    backgroundColor: "#2824271F",
    paddingY: "10px",
    paddingX: "24px",
    alignItems: "center",
    display: "flex",
    gap: "6px",
  },
  publishText: {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.2px",
  },
};
