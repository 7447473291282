import { CssBaseline } from "@mui/material";
import "./App.css";
import {
  UserAuthContext,
  UserAuthContextProvider,
} from "./contexts/UserAuthContext";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  UserPrefContext,
  UserPrefContextProvider,
} from "./contexts/UserPrefContext";
import { NotFoundPage } from "./pages/not-found/NotFoundPage";
import { HomePage } from "./pages/home/HomePage";
import { AuthenticationPage } from "./pages/authentication/AuthenticationPage";
import ResponsiveAppBar from "./component/Appbar";
import { Login } from "./pages/authentication/Login";
import { RewardList } from "./pages/home/RewardList";
import { Rewardcreate } from "./pages/home/Rewardcreate";
import { ERewards } from "./pages/home/ERewards";
import { NewEReward } from "./pages/home/NewEReward";
import { IssueReward } from "./pages/home/IssueReward";
import Confirmation from "./pages/home/Confirmation";
import RedeemReward from "./pages/home/RedeemReward";
import QRScan from "./pages/home/QRScan";
import Profile from "./pages/home/Profile";
import EditName from "./pages/home/EditName";
import EditPassword from "./pages/home/EditPassword";
import EditEmail from "./pages/home/EditEmail";
import { EditReward } from "./pages/home/EditReward";
import IssueRewardDetails from "./pages/home/IssueRewardDetails";

const App = () => {
  return (
    <UserAuthContextProvider>
      <UserAuthContext.Consumer>
        {({ isUserLoggedIn }) => {
          return (
            <UserPrefContextProvider>
              <UserPrefContext.Consumer>
                {({ theme }) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <BrowserRouter>
                        <Routes>
                          <Route
                            path="/"
                            element={
                              isUserLoggedIn ? (
                                <Navigate to="/Home" />
                              ) : (
                                <Navigate to="/auth/login" />
                              )
                            }
                          />
                          <Route
                            path="/auth/registration"
                            element={
                              <>
                                {isUserLoggedIn ? (
                                  <Navigate to="/Home" />
                                ) : (
                                  <>
                                    <ResponsiveAppBar
                                      isUserLoggedIn={isUserLoggedIn}
                                    />
                                    <AuthenticationPage />
                                  </>
                                )}
                              </>
                            }
                          />
                          <Route
                            path="/auth/login"
                            element={
                              <>
                                {isUserLoggedIn ? (
                                  <Navigate to="/Home" />
                                ) : (
                                  <>
                                    <ResponsiveAppBar
                                      isUserLoggedIn={isUserLoggedIn}
                                    />
                                    <Login />
                                  </>
                                )}
                              </>
                            }
                          />
                          <Route
                            path="/Home"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <HomePage />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/rewardlist"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <RewardList />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/rewardcreate"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Rewardcreate />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/editreward/:id"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <EditReward />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/eRewards"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <ERewards />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/newEreward"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <NewEReward />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/IssueReward"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <IssueReward />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/Confirmation"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Confirmation />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/qrscan"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <QRScan />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/RedeemReward/:id"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <RedeemReward />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />

                          <Route
                            path="/IssueRewardDetails/:id"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <IssueRewardDetails />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />

                          <Route
                            path="/profile"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Profile />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/editName"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <EditName />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/editPassword"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <EditPassword />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route
                            path="/editEmail"
                            element={
                              isUserLoggedIn ? (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <EditEmail />
                                </>
                              ) : (
                                <>
                                  <ResponsiveAppBar
                                    isUserLoggedIn={isUserLoggedIn}
                                  />
                                  <Navigate to="/auth/login" />
                                </>
                              )
                            }
                          />
                          <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                      </BrowserRouter>
                    </ThemeProvider>
                  );
                }}
              </UserPrefContext.Consumer>
            </UserPrefContextProvider>
          );
        }}
      </UserAuthContext.Consumer>
    </UserAuthContextProvider>
  );
};

export default App;
