import { LoadingButton } from "@mui/lab";
import { Alert, Box, IconButton, InputAdornment, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Constants } from "../../Constants";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AUTH_MUTATION } from "../../GraphQL/Mutation";
import { GET_USER_DETAILS } from "../../GraphQL/Query";

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup.string().min(8, "Password should be of minimum 8 characters length.").required('Password is required'),
})

export const Login = () => {
    const { t } = useTranslation();
    const { setUserData, isUserLoggedIn } = useContext(UserAuthContext);
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const [open, setOpen] = React.useState(false);
    const [login, { loading, data, error }] = useMutation(AUTH_MUTATION)
    const [getUserDetails, { data: userData, loading: userDataLoading, error: userDataError }] = useLazyQuery(GET_USER_DETAILS);
    const navigate = useNavigate();
    const [loginError, setLoginError] = React.useState(false);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setLoginError(false);
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const result = await login({
                variables: {
                    email: values.email.toLowerCase(),
                    password: values.password,
                },
                });
                if (result.data?.authenticateUserWithPassword?.__typename !== 'UserAuthenticationWithPasswordSuccess') {
                setLoginError(true);
                } else {
                getUserDetails({
                    variables: {
                    email: formik.values.email.toLowerCase(),
                    },
                });
                }
            } catch (error) {
                console.error(error);
            }
            },
        });
        console.log("loginError",loginError)

    useEffect(() => {
        // if (data?.authenticateUserWithPassword?.__typename === 'UserAuthenticationWithPasswordSuccess') {
        //     getUserDetails({
        //         variables: {
        //             email: formik.values.email
        //         }
        //     })
           
        // } 
        if (userData) {
            setUserData({
                email: userData?.user?.email,
                name: userData?.user?.name,
                id: userData?.user?.id
            })
            if (isUserLoggedIn) {
                navigate('/Home')
            }
        }
    }, [data, userData])

    useEffect(() => {
        if (userDataError || error) {
            setOpen(true)
        }
    }, [userDataError, error])

    return (
        <Stack
            direction="column"
            alignItems="center"
            alignContent={'flex-start'}
            justifyContent={'flex-start'}
            justifyItems={'flex-start'}
            height="100vh"
            width="100vw"
        >
            <Box sx={{ backgroundColor: '#142A50', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: '32px', paddingLeft: '32px', paddingRight: '32px', paddingBottom: '32px' }}>
                <Typography fontWeight={'500'} fontSize={'24px'} lineHeight={'32px'} letterSpacing={'-0.18px'} color={'#FFFFFF'}>{t('welcome-back')}</Typography>
                <Typography fontWeight={'400'} fontSize={'16px'} lineHeight={'24px'} color={'#B3B3BE'}>
                    {t('wlcome-back-desc')}
                </Typography>
            </Box>

            <Box>
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{ width: '100vw', height: 'auto', marginTop: '32px', paddingLeft: "32px", paddingRight: '32px', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            required id="email" name="email" label={t('email')} variant="outlined" fullWidth />
                        <Box sx={{ width: '100%' }}>
                            <TextField
                                required
                                value={formik.values.password}
                                fullWidth
                                // }}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                id="password" name="password"
                                label={t('password')}
                                type={values.showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}>
                                                {values.showPassword ? <VisibilityOff color={'secondary'} /> : <Visibility color={'secondary'} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Typography sx={{ fontWeight: '400', marginTop: "12px", fontSize: '13px', lineHeight: '18px', letterSpacing: '0.4px', color: '#AD0067' }}>
                                {t('forgot-password')}</Typography>
                        </Box>
                    </Box>

                    <Box sx={{ width: '100vw', height: 'auto', marginTop: '20px', paddingTop: '16px', paddingLeft: "32px", paddingRight: '32px', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                        <LoadingButton
                            type='submit'
                            loading={loading ?? userDataLoading}
                            loadingPosition='center'
                            disabled={loading ?? userDataLoading}
                            sx={{ height: "48px", borderRadius: '4px' }} variant="contained" disableElevation>
                            <Typography sx={{
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '24px',
                                letterSpacing: '-0.2px',
                                textTransform: 'none',
                            }}>{t('login')}</Typography>
                        </LoadingButton>
                    </Box>
                </form>
            </Box>
            <Box sx={{ width: '100vw', height: 'auto', marginTop: '40px', paddingLeft: "32px", paddingRight: '32px', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#282427' }}>
                    {t('new-to-lean-to-learn')}   <Typography display={'inline'} sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: Constants.color.primary }}
                        onClick={() => navigate('/auth/registration')}
                    >{t('sign-up')}</Typography> </Typography>
            </Box>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} variant='standard' sx={{ width: '100%' }} severity='error'>Something went wrong!</Alert>
            </Snackbar>
            <Snackbar open={loginError} autoHideDuration={6000} onClose={handleClose}>
                <Alert severity="error" variant='standard' sx={{ width: '100%' }} onClose={handleClose}>Credential is wrong!</Alert>
            </Snackbar>

        </Stack>
    );
};
