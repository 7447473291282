import { CircularProgress, FormControl, Grid, ImageListItem, InputBase, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { RewardListItem } from "./RewardListItem";
import { useQuery } from "@apollo/client";
import { GET_REWARD_LIST } from "../../GraphQL/Query";
import { useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { rewardType } from "../type";

const SearchIconWrapper = styled('div')(({ theme }) => ({
    paddingRight: '9px',
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));


export const RewardList = () => {
    const { userData } = useContext(UserAuthContext);
    const { data, loading, error, fetchMore, called, refetch } = useQuery(GET_REWARD_LIST, {
        variables: {
            email: userData?.email,
            limit: 8,
            skip: 0,
            orderby: "desc"
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const [searchedTerm, setSearchedTerm] = useState('');
    const [option, setOption] = useState<string[]>([]);
    const navigate = useNavigate();
    const handleChange = (event: SelectChangeEvent<typeof option>) => {
        const {
            target: { value },
        } = event;
        setOption(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const sortByOption = [
        {
            'key': "Sort by name accending(A-Z)",
            "val": "A-Z"
        }, {
            'key': "Sort by name decending (Z-A)",
            "val": "Z-A"
        }
    ];


    useEffect(() => {
        if (!userData?.email) {
            navigate('/auth/login')
        }
    }, [userData?.email])

    useEffect(() => {
        if (option[0] === 'Z-A') {
            refetch({ orderby: 'desc' })
        } else {
            refetch({ orderby: 'asc' })
        }
    }, [option])

    return (
        <>
            <Stack
                sx={style.main}
            >
               
                <Box sx={style.titleParent}
                    borderBottom={1}
                    borderColor={'#E1E6E8'}
                >
                    <Typography sx={style.rewardText}> Rewards</Typography>
                </Box>

                <Box sx={style.searchParent}>
                    <TextField
                        required
                        fullWidth
                        sx={style.searchinput}
                        placeholder={'Search'}
                        onChange={(e) => setSearchedTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                </>
                            )
                        }}
                    />
                    
                    <FormControl sx={style.formselect}>
                        <Select
                            value={option}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="">
                                <Typography sx={{
                                    fontWeight: '500', fontSize: '16px',
                                    lineHeight: '24px',
                                    letterSpacing: '-0.2px',
                                    color: '#282427'
                                }}>Sort</Typography>
                            </MenuItem>
                            {sortByOption.map((option) => (
                                <MenuItem
                                    key={option.key}
                                    value={option.val}
                                >
                                    {option.key}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box borderBottom={1}
                    sx={{ marginTop: '16px' }}
                    borderColor={'#E1E6E8'}></Box>

                
                {loading && <Box sx={{ display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
                    <CircularProgress sx={{color:"#AD0067"}} /> </Box>}

                {data?.rewards?.length === 0 && <Box sx={{ display:"flex",justifyContent:"center",alignItems:"center",height:"100vh", color:"inherit", fontSize:"22px"}}>
                    No Reward Found! </Box>}
               
                
                {error && <Box sx={style.errorAndLoading}
                > <Typography sx={style.text}>Somthing went wrong!</Typography></Box>}
                    
                {!error && data?.rewards.length > 0 && <InfiniteScroll
                    style={style.scroll}
                    loadMore={(page) => {
                        fetchMore({
                            variables: {
                                skip: data?.rewards?.length
                            },
                        })
                    }
                    }

                    hasMore={data?.rewards?.length < data?.rewardsCount ? true : false}
                    loader={<Box key="loader" sx={style.errorAndLoading}
                    >  {loading && <Typography>"Loading..."</Typography>}</Box>}
                >
                    {(data?.rewards ?? []).filter((reward: rewardType) => {
                        const rewardName = reward.RewardName.toLowerCase();
                        const searchTerm = searchedTerm.toLowerCase();
                            return rewardName.includes(searchTerm);
                    }).map((reward: rewardType, index: number) => (
                    <RewardListItem key={reward.id} reward={reward} index={index}  />
                    ))}
                  </InfiniteScroll>
                }
                <ImageListItem
                    sx={style.itemList}
                    onClick={() => navigate('/rewardcreate')}
                >
                    <img
                        src={require('../../assets/FAB.png')}
                        style={style.flotingButtonIcon}
                        alt={'Logo'}
                        loading="lazy"
                    />
                </ImageListItem>
            </Stack>
        </>)
}



const style = {
    main: {
        height: '100vh', width: '100vw',
        // marginTop: '70px',
    },
    titleParent: {
        // height: "56px",
        paddingTop: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '16px',
        paddingBottom: '16px'
    },
    rewardText: {
        fontWeight: '500',
        fontSize: '22px',
        lineHeight: '32px'
    },
    searchParent: { display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginTop: '16px', gap: '8px', marginRight: '16px', marginLeft: '16px' },
    searchinput: {
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        justifySelf: 'center',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.35px',
    },
    selectForm: { m: 1, width: '130px' },
    formselect: { m: 1, width: '130px' },
    errorAndLoading: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: '16px'
    },
    text: { display: 'flex', fontWeight: '400', fontSize: '16px', lineHeight: '24px', letterSpacing: '-0.2px', color: '#70666C' },
    scroll: {
        // marginTop: '16px'
    },
    itemList: {
        position: 'fixed',
        bottom: 21,
        right: 20,
    },
    flotingButtonIcon: { height: 60, width: 60 }
}